/* global angular */
'use strict'

const moduleName = 'mapamagic.user.validation'

angular.module(moduleName, ['ngStorage'])
  .factory('Validation', () => {
    'ngInject'
    let validator = {
      validateEmail () {
        let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (reg.test(this.value)) {
          this.showMessage = false
        }
        return reg.test(this.value)
      },
      validatePassword () {
        if (this.value === null || this.value.length < 8) {
          return false
        } else {
          return true
        }
      },
      validateOther () {
        if (this.value === null || this.value.length === 0) {
          return false
        } else {
          return true
        }
      }
    }
    return validator
  })

export default moduleName
