/* global angular, scrollView, google */
'use strict'

import scrollView from 'scroll-into-view'// eslint-disable-line

const moduleName = 'mapamagic.user.modelPage'

angular.module(moduleName, [])
  .factory('PageModel', ($window, $rootScope, $state, $http, $interval, $timeout, Mark, newSocket, $localStorage) => {
    'ngInject'
    let moment = require('moment')
    let scrollIntoViewAnimated = require('scroll-into-view')
    let adress = require('../../base/constants.js.dist')
    let center = Mark.center

    let pos = {
      variables: {
        timer: null,
        favouriteClick: false,
        showMapSpinner: false,
        added: false,
        tab: 'First',
        inside: false,
        stopsIdArr: [],
        showClosestStops: true,
        showSpinner: false,
        smoothShowSpinner: false,
        coords: [],
        centralMarker: [],
        center: center,
        directionMarkers: [],
        allMarkers: [],
        markersEvents: {},
        activeModel: {},
        activeModelForBuble: {},
        windowOptions: {},
        stops: [],
        showNotification: true,
        pngMarkers: [],
        busClicked: false,
        showSidebar: true,
        tall: false,
        hiddenDiv: false,
        showButtons: true,
        checked: 'buses',
        scrollTo: '',
        mainMarker: []
      },
      timeouts: {
        mapEventZoomChangedTimeout: false,
        viewChangeEventZoomChangedTimeout: false,
        tabChangeEventZoomChangedTimeout: false,
        sidebarToggleEventInsideTimeout: false,
        resizeTimeout: false,
        checkMarkerDataForBubleTimeout: false,
        checkMarkerOnClickOnCloseButtonFirstTimeout: false,
        checkMarkerOnClickOnCloseButtonSecondTimeout: false,
        processingGeodataFromSocketServiceTimeout: false,
        moveMarkerTimeout: false,
        moveMarkerInsideTimeout: false,
        windowManipulationForRouteClickEventOnTheMainMapTimeout: false,
        windowManipulationForRouteClickEventOnTheMainMapInsideTimeout: false,
        sidebarFavouriteCheckTimeout: false
      },
      socketStopsTimes: [],
      infoCurrentElement: {},
      permissionForTransport: {},
      linePermissionForTransport: {},
      resizeCounter: 0,
      listOfRoutesForRunOnBable: [],
      // function for update coords after login
      updateCoords () {
        const cityId = $localStorage.userInfo.data.city
        const cityCoords = adress.citiesCoords.find(city => city.cityId === cityId).coords
        const { lat, lng } = cityCoords

        center.coords.latitude = lat
        center.coords.longitude = lng
        return { lat, lng }
      },
      logout () {
        // socketService.geodata([]);
        // socketService.subscribe([]);
        $interval.cancel(pos.variables.timer)
        pos.variables.timer = null
        pos.variables.showMapSpinner = false
        pos.variables.favouriteClick = false
        pos.variables.center = center
        newSocket.emit('subscribe', {'type': 'geodata', 'subject': []})
        newSocket.emit('subscribe', {'type': 'stops-time', 'subject': []})
        pos.variables.added = false
        pos.variables.tab = 'First'
        pos.variables.stopsIdArr = []
        pos.variables.showClosestStops = true
        pos.variables.showSpinner = false
        pos.variables.smoothShowSpinner = false
        pos.variables.coords = []
        pos.variables.centralMarker = []
        pos.variables.directionMarkers = []
        pos.variables.allMarkers = []
        pos.variables.markersEvents = {}
        pos.variables.activeModel = {}
        pos.variables.windowOptions = {}
        pos.variables.stops = []
        pos.variables.showNotification = true
        pos.variables.pngMarkers = []
        pos.variables.busClicked = false
        pos.variables.showSidebar = true
        pos.variables.tall = false
        pos.variables.hiddenDiv = false
        pos.showButtons = true
        pos.variables.checked = 'buses'
        pos.variables.scrollTo = ''
        pos.variables.mainMarker = []
        Object.keys(pos.timeouts).forEach(key => {
          pos.clearForDynamicTimeouts(pos.timeouts[key])
        })
        $localStorage.$reset()
        $state.go('mapa.registration')
      },
      isMobile: {
        Android: function () {
          return navigator.userAgent.match(/Android/i)
        },
        BlackBerry: function () {
          return navigator.userAgent.match(/BlackBerry/i)
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i)
        },
        Opera: function () {
          return navigator.userAgent.match(/Opera Mini/i)
        },
        Windows: function () {
          return navigator.userAgent.match(/IEMobile/i)
        },
        any: function () {
          return (pos.isMobile.Android() || pos.isMobile.BlackBerry() || pos.isMobile.iOS() || pos.isMobile.Opera() || pos.isMobile.Windows())
        }
      },
      showPermissionsForAllStopsAtTheSidebar () {
        for (let stop = 0; stop < pos.variables.stops.length; stop++) {
          pos.permissionForTransport[pos.variables.stops[stop].id] = {
            buses: false,
            trolleybuses: false,
            marshrutka: false
          }
          if (pos.variables.stops[stop].trolleybusTimes) {
            for (let time = 0; time < pos.variables.stops[stop].trolleybusTimes.length; time++) {
              if (pos.variables.stops[stop].trolleybusTimes[time].type === 2) {
                pos.permissionForTransport[pos.variables.stops[stop].id].buses = true
              } else if (pos.variables.stops[stop].trolleybusTimes[time].type === 3) {
                pos.permissionForTransport[pos.variables.stops[stop].id].marshrutka = true
              } else if (pos.variables.stops[stop].trolleybusTimes[time].type === 1) {
                pos.permissionForTransport[pos.variables.stops[stop].id].trolleybuses = true
              }
            }
          }
        }
        // pos.variables.stops.forEach(stop => {
        //   pos.permissionForTransport[stop.id] = {
        //     buses: false,
        //     trolleybuses: false,
        //     marshrutka: false
        //   }
        //   if (stop.trolleybusTimes) {
        //     stop.trolleybusTimes.forEach((time, index) => {
        //       if (time.type === 2) {
        //         pos.permissionForTransport[stop.id].buses = true
        //       } else if (time.type === 3) {
        //         pos.permissionForTransport[stop.id].marshrutka = true
        //       } else if (time.type === 1) {
        //         pos.permissionForTransport[stop.id].trolleybuses = true
        //       }
        //     })
        //   }
        // })
      },
      showLinePermissionsForLastStopAtTheSidebarList (type, sid, message) {
        if (message === 'show') {
          pos.linePermissionForTransport[`${type}.${sid}`] = true
        } else if (message === 'hideAll') {
          for (let line in pos.linePermissionForTransport) {
            pos.linePermissionForTransport[line] = false
          }
        } else {
          pos.linePermissionForTransport[`${type}.${sid}`] = false
        }
      },
      createMarker (data) {
        let routeSid = []
        let marker = {}
        for (let i = 0; i < data.routes.length; i++) {
          for (let j = 0; j < $localStorage.dataCatch.routes.length; j++) {
            if (data.routes[i] === $localStorage.dataCatch.routes[j].id) {
              routeSid.push($localStorage.dataCatch.routes[j].sid)
            }
          }
        }
        marker = {
          id: data.id,
          coords: {
            latitude: data.lat,
            longitude: data.lng
          },
          options: {
            icon: {
              url: 'dist/images/otherPin.png',
              scaledSize: {
                width: 23,
                height: 37
              }
            },
            window: {
              title: data.title,
              description: data.description
            },
            idKey: data.id
          },
          window: {
            title: data.title,
            description: data.description
          },
          hidden: data.hidden,
          routes: data.routes,
          routesName: routeSid,
          trolleybusTimes: []
        }
        if (!data.description || data.description === null) {
          marker.window.description = ''
        } else {
          marker.window.description = data.description
        }
        if (!data.title || data.title === null) {
          marker.window.title = ''
        } else if (data.title.length > 25) {
          marker.window.title = `${data.title.slice(0, 25)}...`
        } else {
          marker.window.title = data.title
        }
        if ($localStorage.userInfo.data.favouriteStops.find(favouriteStop => favouriteStop.busStop === data.id)) {
          marker.favouriteStop = true
        } else {
          marker.favouriteStop = false
        }
        // for (let favouriteStop in $localStorage.userInfo.data.favouriteStops) {
        //   if ($localStorage.userInfo.data.favouriteStops[favouriteStop].busStop === data.id) {
        //     marker.favouriteStop = true
        //     pos.variables.stops.push(marker)
        //     return
        //   } else {
        //     marker.favouriteStop = false
        //   }
        // }
        pos.variables.stops.push(marker)
      },
      getData (api) {
        return new Promise(resolve => {
          $timeout(() => {
            const apiKey = $localStorage.userInfo.data.apikey
            $http({
              method: 'GET',
              cache: false,
              url: adress.siteLive + api,
              headers: {
                'AUTH-KEY': apiKey,
                'Cache-Control' : 'no-cache'
              }
            })
              .then(response => {
                if (!$localStorage.dataCatch) {
                  $localStorage.dataCatch = {
                    bus: {},
                    routes: {},
                    sample: {},
                    stops: {}
                  }
                }
                if (api === 'routes') {
                  $localStorage.dataCatch.routes = response.data
                } else if (api === 'sample') {
                  $localStorage.dataCatch.sample = response.data
                } else if (api === 'bus') {
                  $localStorage.dataCatch.bus = response.data
                } else if (api === 'stops') {
                  $localStorage.dataCatch.stops = response.data
                }
                pos.showPermissionsForAllStopsAtTheSidebar()
                resolve(response.data)
              })
              .catch(error => {
                console.log('got an error in processing ', error)
                $window.location.reload()
              })
          }, 0)
        })
      },
      sortStops (center) {
        center = center || pos.variables.center
        for (let i = 0; i < pos.variables.stops.length; i++) {
          if (!google) {
            let sortStopsGooGleNoneTimeout = $timeout(() => {
              pos.clearForDynamicTimeouts(sortStopsGooGleNoneTimeout)
              $window.location.reload()
            }, 10000)
          }
          let mapCenter = new google.maps.LatLng(center.coords.latitude, center.coords.longitude)
          let marker = new google.maps.LatLng(pos.variables.stops[i].coords.latitude, pos.variables.stops[i].coords.longitude)
          let distance = (google.maps.geometry.spherical.computeDistanceBetween(mapCenter, marker)).toFixed()
          pos.variables.stops[i].distance = +distance
          pos.variables.stops[i].options.icon.url = 'dist/images/otherPin.png'
        }
        pos.variables.stops.sort((a, b) => {
          if (+a.distance > +b.distance) {
            return 1
          } else if (+a.distance < +b.distance) {
            return -1
          }
        })
        for (let j = 0; j < 3; j++) {
          if (pos.variables.stops[j].id === pos.variables.added.id) {
            pos.variables.added = false
          }
        }
        if (pos.variables.stops.length >= 3) {
          pos.variables.stops[0].options.icon.url = 'dist/images/pin_first.png'
          pos.variables.mainMarker = pos.variables.stops[0]
          pos.variables.stops[1].options.icon.url = 'dist/images/pin_second.png'
          pos.variables.stops[2].options.icon.url = 'dist/images/pin_third.png'
        }
      },
      markerClick (marker) {
        if (marker.model.idKey !== pos.variables.stops[0].id && marker.model.idKey !== pos.variables.stops[1].id && marker.model.idKey !== pos.variables.stops[2].id) {
          pos.variables.added = pos.variables.stops.find(stop => stop.id === marker.model.idKey)
          pos.variables.added.added = true
          // pos.variables.stops.forEach(e => {
          //   if (e.id === marker.model.idKey) {
          //     e.added = true
          //     pos.variables.added = e
          //   }
          // })
        }
      },
      getDataViaXmlhttprequest (api) {
        return $http.get(`${adress.siteLive}${api}`, {headers: {'AUTH-KEY': $localStorage.userInfo.data.apikey}})
          .then(response => {
            pos.showPermissionsForAllStopsAtTheSidebar()
            return response
          })
          .catch(error => console.log('got an error in processing', error))
      },
      createAllStops (dataGot) {
        dataGot.stops.forEach(stop => {
          pos.createMarker(stop)
        })
        pos.variables.stops = pos.variables.stops.filter(e => {
          return e.hidden !== true
        })
      },
      rewriteStops (socketData, dataRoutes) {
        pos.listOfRoutesForRunOnBable = []
        pos.variables.stops.forEach(stop => {
          if (!stop) {
            return
          }
          if (socketData.find(route => stop.id === route.stop)) {
            stop.trolleybusTimes = []
          }
          // socketData.forEach(route => {
          //   if (stop.id === route.stop) {
          //     stop.trolleybusTimes = []
          //   }
          // })
          for (let socketRoute in socketData) {
            if (stop.id === socketData[socketRoute].stop) {
              for (let stopRoute = 0; stopRoute < stop.routes.length; stopRoute++) {
                if (stop.routes[stopRoute] === socketData[socketRoute].route) {
                  stop.trolleybusTimes.push({
                    times: socketData[socketRoute].times,
                    timesRoute: socketData[socketRoute].route
                  })
                  for (let time = 0; time < stop.trolleybusTimes.length; time++) {
                    if (stop.routes[stopRoute] === stop.trolleybusTimes[time].timesRoute) {
                      stop.trolleybusTimes[time].routesName = stop.routesName[stopRoute]
                    }
                  }
                }
              }
            }
          }
          for (let route = 0; route < stop.trolleybusTimes.length; route++) {
            for (let element = 0; element < dataRoutes.length; element++) {
              if (dataRoutes[element].id === stop.trolleybusTimes[route].timesRoute) {
                stop.trolleybusTimes[route].type = dataRoutes[element].type
              }
            }
          }
          // stop.trolleybusTimes.forEach(route => {
          //   dataRoutes.forEach(element => {
          //     if (element.id === route.timesRoute) {
          //       route.type = element.type
          //     }
          //   })
          // })
        })
        if ($localStorage.dataCatch) {
          $rootScope.$broadcast('rewriteListOfStopsAndFindWeNeededStopInList')
          pos.createlistOfRoutesForRunOnBable()
          pos.showPermissionsForAllStopsAtTheSidebar()
        }
      },
      permissionToDrawBuble: false,
      createlistOfRoutesForRunOnBable () {
        pos.listOfRoutesForRunOnBable = []
        if (pos.listOfRoutesForRunOnBable.length === 0) {
          for (let stop = 0; stop < pos.variables.stops.length; stop++) {
            pos.listOfRoutesForRunOnBable.push({
              id: pos.variables.stops[stop].id,
              routes: pos.variables.stops[stop].trolleybusTimes.map(route => {
                return {
                  routeName: route.routesName,
                  type: route.type
                }
              }),
              index: pos.variables.stops[stop].routes
            })
          }
          // pos.variables.stops.forEach(stop => {
          //   let arrayOfRoutes = []
          //   let arrayOfInd = []
          //   arrayOfInd = stop.routes
          //   stop.trolleybusTimes.forEach(route => {
          //     arrayOfRoutes.push({
          //       routeName: route.routesName,
          //       type: route.type
          //     })
          //   })
          //   pos.listOfRoutesForRunOnBable.push({
          //     id: stop.id,
          //     routes: arrayOfRoutes,
          //     index: arrayOfInd
          //   })
          // })
        } else {
          for (let stop = 0; stop < pos.variables.stops.length; stop++) {
            if (!pos.listOfRoutesForRunOnBable.find(route => pos.variables.stops[stop].id === route.id) && stop.trolleybusTimes.length !== 0) {
              pos.listOfRoutesForRunOnBable = {
                id: pos.variables.stops[stop].id,
                routes: pos.variables.stops[stop].trolleybusTimes.map(route => {
                  return {
                    routeName: route.routesName,
                    type: route.type
                  }
                }),
                index: pos.variables.stops[stop].routes
              }
            }
          }
          // pos.variables.stops.forEach(stop => {
          //   pos.listOfRoutesForRunOnBable.forEach(route => {
          //     if (stop.id === route.id && stop.trolleybusTimes.length !== 0) {
          //       let arrayOfRoutes = []
          //       let arrayOfInd = []
          //       arrayOfInd = stop.routes
          //       stop.trolleybusTimes.forEach(route => {
          //         arrayOfRoutes.push({
          //           routeName: route.routesName,
          //           type: route.type
          //         })
          //       })
          //       pos.listOfRoutesForRunOnBable = {
          //         id: stop.id,
          //         routes: arrayOfRoutes,
          //         index: arrayOfInd
          //       }
          //     }
          //   })
          // })
        }
        pos.rewritelistOfRoutesForRunOnBable()
      },
      // need to optimize
      rewritelistOfRoutesForRunOnBable () {
        let arr = []
        let dataIndex
        let arrIndex
        pos.listOfRoutesForRunOnBable.forEach(route => {
          if (!dataIndex || dataIndex !== route.id) {
            dataIndex = route.id
            arr.push(route)
          } else if (dataIndex === route.id) {
            dataIndex = route.id
            arr.forEach((element, index) => {
              if (element.id === route.id) {
                arrIndex = index
              }
            })
            arr[arrIndex] = route
          }
        })
        pos.listOfRoutesForRunOnBable = arr
        for (let j = 0; j < pos.listOfRoutesForRunOnBable.length; j++) {
          pos.listOfRoutesForRunOnBable[j].routesAll = []
        }
        for (let i = 0; i < $localStorage.dataCatch.routes.length; i++) {
          for (let j = 0; j < pos.listOfRoutesForRunOnBable.length; j++) {
            arr[j].index.forEach(route => {
              if (route === $localStorage.dataCatch.routes[i].id) {
                pos.listOfRoutesForRunOnBable[j].routesAll.push({
                  routeName: $localStorage.dataCatch.routes[i].sid,
                  type: $localStorage.dataCatch.routes[i].type
                })
              }
            })
          }
        }
        if (pos.permissionToDrawBuble) {
          $rootScope.$broadcast('functionToRewriteBuble')
        }
      },
      //
      rewriteData (socketData) {
        socketData = pos.rewriteDataQueue(socketData)
        for (let socket in socketData) {
          socketData[socket].times = pos.dataTimeFromServer(socketData[socket].times)
        }
        return socketData
      },
      // need to optimize
      rewriteDataQueue (socketData) {
        let value = 0
        let secondValue = 0
        for (let socket = 0; socket < socketData.length - 1; socket++) {
          let min = socket
          for (let minSocket = socket + 1; minSocket < socketData.length; minSocket++) {
            socketData[min].times.forEach(time => {
              if (value === 0) {
                value = time.time
              }
              if (time.time < value) {
                value = time.time
              }
            })
            socketData[minSocket].times.forEach(time => {
              if (secondValue === 0) {
                secondValue = time.time
              }
              if (time.time < secondValue) {
                secondValue = time.time
              }
            })
            if (secondValue < value) {
              min = minSocket
            }
          }
          let timeValue = socketData[min]
          socketData[min] = socketData[socket]
          socketData[socket] = timeValue
        }
        return socketData
      },
      //
      dataTimeFromServer (secArray) {
        for (let i = 0; i < secArray.length; i++) {
          if (typeof (secArray[i].time) !== 'string') {
            let curentTime = moment.duration(secArray[i].time * 1000)
            let hours = curentTime.hours()
            let minutes = curentTime.minutes() === 0 ? 0 : curentTime.minutes() + 1
            let seconds = curentTime.seconds()
            secArray[i].time = hours !== 0 ? `${hours} год. ${minutes} хв`
              : minutes === 0 ? `${seconds} сек` : `${minutes} хв`
          }
        }
        return secArray
      },
      getSocketDataForStorage (data) {
        if ($localStorage.selectedStop) {
          pos.socketStopsTimes = data.concat($localStorage.selectedStop)
        } else {
          pos.socketStopsTimes = data
        }
      },
      writeDataFromXmlhttp (value, dataGot) {
        let socketData = pos.rewriteData(value.data)
        pos.getSocketDataForStorage(socketData)
        pos.rewriteStops(socketData, dataGot.routes)
      },
      addOneSocketDataForStorage (data) {
        if (!$localStorage.selectedStop) {
          $localStorage.selectedStop = {}
        }
        $localStorage.selectedStop = data
        pos.socketStopsTimes = pos.socketStopsTimes.concat(data)
      },
      // need to optimize
      getSocketDataForStorageFromSocket (data) {
        if (pos.socketStopsTimes.length && data.length) {
          pos.socketStopsTimes.forEach((e, i) => {
            if (pos.socketStopsTimes[i].stop === data[0].stop) {
              pos.socketStopsTimes.splice(i, 1)
            }
          })
          pos.socketStopsTimes = pos.socketStopsTimes.concat(data)
        }
      },
      //
      changeShowSidebarListsValue (sid, elementIndex, type, showSidebarLists) {
        for (let list in showSidebarLists) {
          if (list === `show.${type}.${sid}.${elementIndex}`) {
            showSidebarLists[list].permission = !showSidebarLists[list].permission
          } else {
            showSidebarLists[list].permission = false
          }
        }
        return showSidebarLists
      },
      scrollIntoViewAnimated (name, time, top, timeTimeout) {
        if ($window.innerWidth > 1120) {
          let scrollIntoViewAnimatedTimeout = $timeout(() => {
            scrollIntoViewAnimated($window.document.getElementById(name), {
              time: time,
              align: {
                top: top
              }
            })
            pos.clearForDynamicTimeouts(scrollIntoViewAnimatedTimeout)
          }, timeTimeout)
        } else {
          let mainTab = $window.document.getElementsByClassName('sidebar_wrapper ng-scope')[0]
          let scrollIntoViewAnimatedTimeout = $timeout(() => {
            scrollIntoViewAnimated($window.document.getElementsByClassName('tabs')[0], {
              time: time,
              align: {
                top: 0
              }
            })
            mainTab.className = 'sidebar_wrapper ng-scope active'
            pos.clearForDynamicTimeouts(scrollIntoViewAnimatedTimeout)
          }, timeTimeout)
        }
      },
      // need to optimize
      getTypeAndIndexFromWithSocketData (data) {
        for (let stop in pos.variables.stops) {
          if (pos.variables.stops[stop].id === data.sid) {
            pos.variables.stops[stop] = pos.rewriteTimesFromStop(pos.variables.stops[stop])
            let arrayBus = []
            let arrayTrolleybus = []
            for (let i = 0; i < pos.variables.stops[stop].trolleybusTimes.length; i++) {
              if (pos.variables.stops[stop].trolleybusTimes[i].type === 3) {
                arrayBus.push(pos.variables.stops[stop].trolleybusTimes[i])
              } else {
                arrayTrolleybus.push(pos.variables.stops[stop].trolleybusTimes[i])
              }
            }
            for (let i = 0; i < arrayBus.length; i++) {
              if (arrayBus[i].routesName === data.route) {
                data.index = i
                data.rid = arrayBus[i].timesRoute
                data.type = 'bus'
              }
            }
            for (let i = 0; i < arrayTrolleybus.length; i++) {
              if (arrayTrolleybus[i].routesName === data.route) {
                data.index = i
                data.rid = arrayTrolleybus[i].timesRoute
                data.type = 'trolleybus'
              }
            }
          }
        }
        return data
      },
      //
      rewriteTimesFromStop (stop) {
        for (let i = 0; i < stop.trolleybusTimes.length - 1; i++) {
          for (let j = 0; j < stop.trolleybusTimes.length - 1 - i; j++) {
            if (stop.trolleybusTimes[j + 1].times[0] < stop.trolleybusTimes[j].times[0]) {
              let value = stop.trolleybusTimes[j + 1]
              stop.trolleybusTimes[j + 1] = stop.trolleybusTimes[j]
              stop.trolleybusTimes[j] = value
            }
          }
        }
        return stop
      },
      counterRoutes: 0,
      findElementAtTheView (tab, sid, type, elementIndex) {
        pos.counterRoutes = 0
        let routeElement = angular.element($window.document.querySelectorAll('.route_name'))
        let value
        for (let i = 0; i < routeElement.length; i++) {
          if (tab === 'First') {
            if (pos.counterRoutes !== 1 && routeElement[i].id === `${type}.${sid}.${elementIndex}`) {
              routeElement[i].className = `route_name ${sid}-pressed`
              pos.counterRoutes++
            }
          } else {
            if (routeElement[i].id === `${type}.${sid}.${elementIndex}`) {
              routeElement[i].className = `route_name ${sid}-pressed`
              pos.counterRoutes++
            }
          }
        }
        // let permissionElement = routeElement.find(route => route.className === `route_name ${sid}-pressed`)
        // if (permissionElement) {
        //   value = permissionElement
        // }
        for (let i = 0; i < routeElement.length; i++) {
          if (routeElement[i].className === `route_name ${sid}-pressed`) value = routeElement[i]
        }
        return value
      },
      rewriteTheMainRouteClass () {
        let routeElement = angular.element($window.document.querySelectorAll('.route_name'))
        for (let i = 0; i < routeElement.length; i++) {
          if (routeElement[i].className === `route_name ${parseInt(routeElement[i].classList[1])}-pressed`) {
            routeElement[i].className = `route_name ${parseInt(routeElement[i].classList[1])}-unpressed`
          }
        }
      },
      closeListOfStopsByClick () {
        let routeElement = angular.element($window.document.getElementsByClassName('wrapper-live-stops-list-open'))
        for (let i = 0; i < routeElement.length; i++) {
          routeElement[i].className = 'wrapper-live-stops-list'
        }
      },
      // need to optimize
      rewriteListOfStopsAndFindWeNeededStopInList (rid) {
        let stops = []
        let elementSOfDOMOnClick = {}
        for (let j = 0; j < pos.variables.stops.length; j++) {
          for (let i = 0; i < pos.variables.stopsIdArr.length; i++) {
            if (pos.variables.stops[j].id === pos.variables.stopsIdArr[i]) {
              stops.push(pos.variables.stops[j])
            }
          }
        }
        let counterBus = 0
        let counterTrolleybus = 0
        let permissionElement = null
        let index
        for (let j = 0; j < stops.length; j++) {
          for (let i = 0; i < stops[j].trolleybusTimes.length; i++) {
            if (stops[j].trolleybusTimes[i].type !== 3) {
              index = counterBus
              counterBus++
            } else {
              index = counterTrolleybus
              counterTrolleybus++
            }
            let elements = $window.document.getElementById(`${stops[j].trolleybusTimes[i].type !== 3 ? 'trolleybus' : 'bus'}.${stops[j].id}.${index}`)
            if (elements) {
              for (let w = 0; w < elements.classList.length; w++) {
                if (elements.classList[w] === `${stops[j].id}-pressed`) {
                  permissionElement = stops[j]
                }
              }
            }
          }
          counterTrolleybus = counterBus = 0
        }
        counterTrolleybus = counterBus = -1
        if (permissionElement) {
          for (let i = 0; i < permissionElement.trolleybusTimes.length; i++) {
            if (permissionElement.trolleybusTimes[i].type !== 3) {
              index = counterBus
              counterBus++
            } else {
              index = counterTrolleybus
              counterTrolleybus++
            }
            if (rid === permissionElement.trolleybusTimes[i].timesRoute) {
              elementSOfDOMOnClick = {
                index: permissionElement.trolleybusTimes[i].type !== 3 ? counterBus : counterTrolleybus,
                id: permissionElement.id,
                type: permissionElement.trolleybusTimes[i].type !== 3 ? 'trolleybus' : 'bus'
              }
            }
          }
          if (elementSOfDOMOnClick && permissionElement) {
            pos.changeRoutesClassPosition(elementSOfDOMOnClick)
          } else {
            for (let i = 0; i < permissionElement.trolleybusTimes.length; i++) {
              let counter = 0
              if (rid !== permissionElement.trolleybusTimes[i].timesRoute) {
                counter++
              }
              if (counter === permissionElement.trolleybusTimes.length) {
                $rootScope.$broadcast('functionToCloseSidebarListOfStops')
              }
            }
          }
        }
        counterTrolleybus = counterBus = 0
      },
      changeRoutesClassPosition (elementData) {
        let element = $window.document.getElementById(`${elementData.type}.${elementData.id}.${elementData.index}`)
        if (element) {
          for (let i = 0; i < element.classList.length; i++) {
            if (element.classList[i] !== `${elementData.id}-pressed`) {
              angular.element($window.document.querySelectorAll('.route_name')).removeClass('active')
              pos.rewriteTheMainRouteClass()
              element.className = `route_name ${elementData.id}-pressed active`
            }
          }
        }
      },
      //
      clearForDynamicTimeouts (timeout) {
        if (timeout) {
          $timeout.cancel(timeout)
        }
      },
      replaceGMButtons () {
        let timeoutButtonPosition

        function rewriteGMButtonsPosition () {
          timeoutButtonPosition = $timeout(() => {
            // let elements = Object.assign($window.document.getElementsByClassName('gmnoprint').filter(gmnoprint => {
            //   if (gmnoprint.className === 'gmnoprint' && gmnoprint.childNodes[0].className === 'gm-style-mtc') {
            //     return gmnoprint
            //   }
            // }))
            let element = $window.document.getElementsByClassName('gmnoprint')[5]
            if (!element) {
              rewriteGMButtonsPosition()
            } else {
              element.className = 'gmnoprint left'
            }
          }, 200).then(() => $timeout.close(timeoutButtonPosition))
        }

        rewriteGMButtonsPosition()
      }
    }
    return pos
  })
export default moduleName
