'use strict'

import angular from 'angular'

const moduleName = 'mapamagic.user.pRegistration'

angular.module(moduleName, [])
  .controller('PostRegistrationController', function ($window, $localStorage, User, Validation) {
    'ngInject'
    let vm = this
    vm.inputFields = [
      {
        name: 'email',
        value: null,
        controllerType: 'post-registration',
        inputTypeForClassMessage: 'email',
        placeholder: 'Електронна пошта',
        validator: Validation.validateEmail,
        text: 'Email повинен бути у форматі *@*.*',
        modelValue: null,
        showMessage: false,
        submitted: false
      }
    ]
    vm.inputFields[0].value = $localStorage.dataUserToSend.user.email || null
    vm.emailIsDefined = $localStorage.dataUserToSend.user.email
    vm.closeErrorMessage = (data, status, event, validationResult) => {
      event.stopPropagation()
      if (!validationResult) {
        if (status || data === 'closeAll') {
          vm.inputFields.forEach(field => {
            field.showMessage = field.name === data
          })
        }
      } else {
        vm.inputFields.forEach(field => {
          field.showMessage = false
        })
      }
    }
    vm.addPostRegistrationData = () => {
      let dataLogin = {}
      vm.inputFields.forEach(field => {
        field.submitted = true
        dataLogin[field.name] = field.value
      })
      dataLogin.city = 'none'
      dataLogin.country = 'none'
      if (User.checkInputsValidation(vm.inputFields)) User.extraAdd(dataLogin)
    }
    $window.document.onkeyup = event => {
      event = event || $window.event
      if (event.keyCode === 13) {
        vm.addPostRegistrationData()
        vm.inputFields.forEach(field => {
          field.submitted = true
        })
      }
      return false
    }
    vm.closeErrorMassageAfterAnswerFromServer = value => {
      vm[value] = !vm[value]
    }
  })

export default moduleName
