/* global angular, Hammer */
'use strict'
const moduleName = 'mapamagic.base.authConfig'

angular.module(moduleName, ['uiGmapgoogle-maps'])
  .config(($qProvider, uiGmapGoogleMapApiProvider, hammerDefaultOptsProvider) => {
    'ngInject'
    let adress = require('./constants.js.dist')
    hammerDefaultOptsProvider.set({
      recognizers: [
        [Hammer.Tap, {time: 250}],
        [Hammer.Pan, {time: 250}]
      ]
    })

    $qProvider.errorOnUnhandledRejections(false)

    uiGmapGoogleMapApiProvider.configure({
      // client : '190194962614-1ba6ul0ua9c5tim15hp5p0jguq0bhj5g.apps.googleusercontent.com',
      v: '3.28', // 3.24
      libraries: 'geometry',
      key: adress.mapsApikey
    })
  })

export default moduleName
