/* global angular */
'use strict'

const moduleName = 'mapamagic.base.filter.favouriteFilter'

angular.module(moduleName, [])
  .filter('favouriteFilter', function () {
    'ngInject'
    return function (item) {
      let array = []
      item.forEach((element, index) => {
        if (element.favouriteStop) {
          array.push(element)
        }
      })
      return array
    }
  })

export default moduleName
