/* global angular */
'use strict'

const moduleName = 'mapamagic.base.feedbackClickEvent'

angular.module(moduleName, [])
  .factory('clickEventService', PageModel => {
    'ngInject'
    let click = {
      trolleybusPermission: true,
      busPermission: true,
      propertyForControlRoutesIteration: [],
      currentElement: {},
      clickEvent (marker, eventName, model, name) {
        PageModel.variables.windowOptions.boxStyle.height = '65px'
        PageModel.variables.windowOptions.boxStyle.display = 'block'
        PageModel.variables.windowOptions.boxClass = 'infobox'
        if (!model.window) {
          // if click on bus marker
          model.id = model.idKey
          PageModel.variables.windowOptions.content = `<p class="number">${name}</p><p class="text">бортовий номер</p>`
          PageModel.variables.windowOptions.boxStyle.width = '180px'
          PageModel.variables.windowOptions.pixelOffset = { width: -90, height: -120 }
          PageModel.variables.busClicked = true

          if (PageModel.variables.activeModel.id !== model.id) {
            model.show = true
          } else {
            model.show = (model.show === undefined) ? true : !model.show
            if (!model.window) {
              // watch for coordinates for movement of window
              PageModel.variables.busClicked = !PageModel.variables.busClicked
            }
          }
        } else {
          // if click on grey stop marker
          PageModel.variables.busClicked = false
          PageModel.variables.windowOptions.content = `<p class="from">${model.window.title}</p><p class="to"><i class="fa fa-arrow-right" aria-hidden="true"></i>${model.window.description}</p>`
          if (model.window.title === undefined || model.window.title === null) {
            PageModel.variables.windowOptions.boxStyle.width = (model.window.description.length + 5) * 12 + 'px'
          } else if (model.window.description === undefined || model.window.description === null) {
            PageModel.variables.windowOptions.boxStyle.width = (model.window.title.length + 5) * 12 + 'px'
          } else if (model.window.title.length > model.window.description.length + 5) {
            PageModel.variables.windowOptions.boxStyle.width = (model.window.title.length + 5) * 12 + 'px'
          } else {
            PageModel.variables.windowOptions.boxStyle.width = (model.window.description.length + 7) * 12 + 'px'
          }
          PageModel.variables.windowOptions.pixelOffset = { width: -(parseInt(PageModel.variables.windowOptions.boxStyle.width, 10) / 2), height: -135 }
        }
        if (PageModel.variables.centralMarker.coords.latitude === model.coords.latitude) {
          // if click on central marker
          PageModel.variables.windowOptions.content = `<p class="from">${PageModel.variables.centralMarker.window.title}</p><p class="to"><i class="fa fa-arrow-right" aria-hidden="true"></i>${PageModel.variables.centralMarker.window.description}</p>`
          PageModel.variables.busClicked = false
          if (PageModel.variables.centralMarker.window.title === null || PageModel.variables.centralMarker.window.title === undefined) {
            PageModel.variables.windowOptions.boxStyle.width = (PageModel.variables.centralMarker.window.description.length + 5) * 12 + 'px'
          } else if (PageModel.variables.centralMarker.window.description === null || PageModel.variables.centralMarker.window.description === undefined) {
            PageModel.variables.windowOptions.boxStyle.width = (PageModel.variables.centralMarker.window.title.length + 5) * 12 + 'px'
          } else if (PageModel.variables.centralMarker.window.title.length > PageModel.variables.centralMarker.window.description.length + 5) {
            PageModel.variables.windowOptions.boxStyle.width = (PageModel.variables.centralMarker.window.title.length + 5) * 12 + 'px'
          } else {
            PageModel.variables.windowOptions.boxStyle.width = (PageModel.variables.centralMarker.window.description.length + 5) * 12 + 'px'
          }
          PageModel.variables.windowOptions.pixelOffset = { width: -(parseInt(PageModel.variables.windowOptions.boxStyle.width, 10) / 2), height: -135 }
          if (PageModel.variables.activeModel.id !== model.id) {
            model.show = true
          } else {
            PageModel.variables.activeModel.show = (model.show === undefined) ? true : !model.show
          }
        }

        PageModel.variables.activeModel.id = model.id
        PageModel.variables.activeModel.show = model.show
        if (!PageModel.variables.activeModel.coords) {
          PageModel.variables.activeModel.coords = {}
        }
        PageModel.variables.activeModel.coords.latitude = model.coords.latitude
        PageModel.variables.activeModel.coords.longitude = model.coords.longitude
      },
      clickEventMap (marker, model, id, key) {
        PageModel.variables.windowOptionsForBuble.boxStyle.height = '65px'
        PageModel.variables.windowOptionsForBuble.boxStyle.display = 'block'
        PageModel.variables.windowOptionsForBuble.boxClass = 'infobox-inside-route'
        PageModel.variables.busClicked = false
        PageModel.variables.windowOptionsForBuble.content = `
                <div class="buble-wrapper-close-button ${id}-unpressed"
                  onclick="(function (id) {
                      let elements = document.getElementsByClassName('buble-wrapper-close-button')
                      elements[0].className = 'buble-wrapper-close-button ' + id + '-pressed'
                    })(${id})"
                  ontouch="(function (id) {
                      let elements = document.getElementsByClassName('buble-wrapper-close-button')
                      elements[0].className = 'buble-wrapper-close-button ' + id + '-pressed'
                    })(${id})">
                    <div class="buble-close-button"></div>
                </div>
                <p class="from">${marker.window.title}</p>
                <p class="to">
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>${marker.window.description}
                </p>`
        for (let i = 0; i < PageModel.variables.stops.length; i++) {
          if (PageModel.variables.stops[i].id === model.idKey) {
            if (PageModel.variables.stops[i].routesName.length !== 0) {
              if (click.busPermission) {
                PageModel.variables.windowOptionsForBuble.content = `${PageModel.variables.windowOptionsForBuble.content} <ul class="autobus"><div class="bus-buble"></div> `
                for (let j = 0; j < PageModel.variables.stops[i].routesName.length; j++) {
                  PageModel.variables.windowOptionsForBuble.content = `${PageModel.variables.windowOptionsForBuble.content}
                                    ${click.createRouteListTypeString(PageModel.variables.stops[i].routesName[j], id, 3)}`
                }
                PageModel.variables.windowOptionsForBuble.content = `${PageModel.variables.windowOptionsForBuble.content} </ul>`
              }
              if (click.trolleybusPermission) {
                PageModel.variables.windowOptionsForBuble.content = `${PageModel.variables.windowOptionsForBuble.content} <ul class="autobus"><div class="trollebus-buble"></div> `
                for (let j = 0; j < PageModel.variables.stops[i].routesName.length; j++) {
                  PageModel.variables.windowOptionsForBuble.content = `${PageModel.variables.windowOptionsForBuble.content}
                                    ${click.createRouteListTypeString(PageModel.variables.stops[i].routesName[j], id, 1)}`
                }
                PageModel.variables.windowOptionsForBuble.content = `${PageModel.variables.windowOptionsForBuble.content} </ul>`
              }
            } else {
              PageModel.variables.windowOptionsForBuble.content = `${PageModel.variables.windowOptionsForBuble.content} <p class="question-message"> Через цю зупинку не проходять маршрути</p>`
            }
          }
        };
        PageModel.variables.windowOptionsForBuble.content = `${PageModel.variables.windowOptionsForBuble.content} </div>`
        if (marker.window.title === undefined || marker.window.title === null) {
          PageModel.variables.windowOptionsForBuble.boxStyle.width = (marker.window.description.length + 5) * 12 + 'px'
        } else if (marker.window.description === undefined || marker.window.description === null) {
          marker.window.description = ''
          PageModel.variables.windowOptionsForBuble.boxStyle.width = (marker.window.title.length + 5) * 12 + 'px'
        } else if (marker.window.title.length > marker.window.description.length + 5) {
          PageModel.variables.windowOptionsForBuble.boxStyle.width = (marker.window.title.length + 5) * 12 + 'px'
        } else {
          PageModel.variables.windowOptionsForBuble.boxStyle.width = (marker.window.description.length + 7) * 12 + 'px'
        }
        PageModel.variables.windowOptionsForBuble.pixelOffset = { width: -(parseInt(PageModel.variables.windowOptionsForBuble.boxStyle.width, 10) / 2) - 55, height: -230 }
        if (PageModel.variables.windowOptionsForBuble.boxStyle.width <= '170px') {
          PageModel.variables.windowOptionsForBuble.boxStyle.width = '290px'
          PageModel.variables.windowOptionsForBuble.boxStyle.height = '170px'
          PageModel.variables.windowOptionsForBuble.pixelOffset.width = -145
          PageModel.variables.windowOptionsForBuble.pixelOffset.height = -225
          if (!click.trolleybusPermission || !click.busPermission) {
            PageModel.variables.windowOptionsForBuble.boxStyle.height = '145px'
            PageModel.variables.windowOptionsForBuble.pixelOffset.height = -200
          }
        } else if (PageModel.variables.windowOptionsForBuble.boxStyle.width >= '260px' && PageModel.variables.windowOptionsForBuble.boxStyle.width < '310px') {
          PageModel.variables.windowOptionsForBuble.boxStyle.width = '340px'
          PageModel.variables.windowOptionsForBuble.boxStyle.height = '170px'
          PageModel.variables.windowOptionsForBuble.pixelOffset.width = -170
          PageModel.variables.windowOptionsForBuble.pixelOffset.height = -225
          if (!click.trolleybusPermission || !click.busPermission) {
            PageModel.variables.windowOptionsForBuble.boxStyle.height = '145px'
            PageModel.variables.windowOptionsForBuble.pixelOffset.height = -200
          }
        } else if (PageModel.variables.windowOptionsForBuble.boxStyle.width >= '310px') {
          PageModel.variables.windowOptionsForBuble.boxStyle.width = '370px'
          PageModel.variables.windowOptionsForBuble.boxStyle.height = '180px'
          PageModel.variables.windowOptionsForBuble.pixelOffset.width = -185
          PageModel.variables.windowOptionsForBuble.pixelOffset.height = -235
          if (!click.trolleybusPermission || !click.busPermission) {
            PageModel.variables.windowOptionsForBuble.boxStyle.height = '155px'
            PageModel.variables.windowOptionsForBuble.pixelOffset.height = -210
          }
        } else {
          PageModel.variables.windowOptionsForBuble.boxStyle.width = '330px'
          PageModel.variables.windowOptionsForBuble.boxStyle.height = '170px'
          PageModel.variables.windowOptionsForBuble.pixelOffset.width = -165
          PageModel.variables.windowOptionsForBuble.pixelOffset.height = -225
          if (!click.trolleybusPermission || !click.busPermission) {
            PageModel.variables.windowOptionsForBuble.boxStyle.height = '145px'
            PageModel.variables.windowOptionsForBuble.pixelOffset.height = -200
          }
        }
        if (PageModel.variables.activeModelForBuble.id !== model.id ||
            PageModel.variables.activeModelForBuble.id === model.id ||
            model.showBuble === undefined) {
          model.showBuble = true
        }
        PageModel.variables.activeModelForBuble.id = model.idKey
        PageModel.variables.activeModelForBuble.showBuble = model.showBuble
        if (!PageModel.variables.activeModelForBuble.coords) {
          PageModel.variables.activeModelForBuble.coords = {}
        }
        PageModel.variables.activeModelForBuble.coords.latitude = model.coords.latitude
        PageModel.variables.activeModelForBuble.coords.longitude = model.coords.longitude
      },
      createRouteListTypeString (routeMain, id, type) {
        let text = ''
        let permission = false
        let checkRoutes = false
        if (click.propertyForControlRoutesIteration.length === 0) {
          click.propertyForControlRoutesIteration.push({
            type: type,
            route: routeMain
          })
        } else {
          for (let i = 0; i < click.propertyForControlRoutesIteration.length; i++) {
            if (click.propertyForControlRoutesIteration[i].route === routeMain && click.propertyForControlRoutesIteration[i].type === type) {
              checkRoutes = true
            }
          }
          if (checkRoutes) {
            return ''
          } else {
            click.propertyForControlRoutesIteration.push({
              type: type,
              route: routeMain
            })
          }
        }
        PageModel.listOfRoutesForRunOnBable.forEach(routeList => {
          if (routeList.id === id) {
            permission = false
            routeList.routesAll.forEach(routeElement => {
              if (routeElement.type === type && routeElement.routeName === routeMain) {
                text = `<li><p class="content-route">
                                    ${routeMain}
                                </p></li>`
                permission = true
              }
            })
            if (permission && routeList.routes !== 0) {
              routeList.routes.forEach(routeElement => {
                if (routeElement.routeName === routeMain && routeElement.type === type) {
                  text = `<li><a class="content-route ${routeMain}-unpressed" ontouch="(function (route) {
                      let elements = document.getElementsByClassName('content-route')
                      for (let i = 0; i < elements.length; i++) {
                        for (let j = 0; j < elements[i].classList.length; j++) {
                          if (elements[i].classList[j] === route + '-unpressed') {
                            elements[i].className = 'content-route ' + route + '-pressed'
                          }
                        }
                      }
                    })('${routeMain}')" onclick="(function (route) {
                      let elements = document.getElementsByClassName('content-route')
                      for (let i = 0; i < elements.length; i++) {
                        for (let j = 0; j < elements[i].classList.length; j++) {
                          if (elements[i].classList[j] === route + '-unpressed') {
                            elements[i].className = 'content-route ' + route + '-pressed'
                          }
                        }
                      }
                    })('${routeMain}')">
                                        ${routeMain}
                                    </a></li>`
                }
              })
            }
          }
        })
        return text
      },
      getPermissionFromData (id) {
        PageModel.listOfRoutesForRunOnBable.find(routeList => routeList.id === id).routesAll.forEach(routeElement => {
          if (routeElement.type === 1) {
            click.trolleybusPermission = true
          } else if (routeElement.type === 3) {
            click.busPermission = true
          }
        })
        // PageModel.listOfRoutesForRunOnBable.forEach(routeList => {
        //   if (routeList.id === id) {
        //     routeList.routesAll.forEach(routeElement => {
        //       if (routeElement.type === 1) {
        //         click.trolleybusPermission = true
        //       }
        //       if (routeElement.type === 3) {
        //         click.busPermission = true
        //       }
        //     })
        //   }
        // })
      }
    }
    return click
  })
export default moduleName
