/* global angular */
'use strict'

const feedbackTemplate = './app/partials/templates/popupFeedback.html'
const socialShareTemplate = './app/partials/templates/popupSocialShare.html'
const ratingTemplate = './app/partials/templates/popupRating.html'
const questionTemplate = './app/partials/templates/popupQuestion.html'
const downloadTemplate = './app/partials/templates/popupDownload.html'

const moduleName = 'mapamagic.directives.directivePopups'
angular.module(moduleName, [])
  .directive('popUps', () => {
    'ngInject'
    return {
      restrict: 'E',
      templateUrl: function (element, attributes) {
        if (attributes.type === 'rating') {
          return ratingTemplate
        } else if (attributes.type === 'social') {
          return socialShareTemplate
        } else if (attributes.type === 'feedback') {
          return feedbackTemplate
        } else if (attributes.type === 'question') {
          return questionTemplate
        } else if (attributes.type === 'download') {
          return downloadTemplate
        }
      },
      scope: '= this'
    }
  })

export default moduleName
