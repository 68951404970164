/* global angular, google */
'use strict'

const moduleName = 'mapamagic.base.serviceMap'

angular.module(moduleName, [])
  .factory('liveService', ($window, $rootScope, $localStorage, $timeout, $q, newSocket, PageModel, clickEventService) => {
    'ngInject'
    let adress = require('../constants.js.dist')
    let live = {
      imeisLength: 0,
      routeId: undefined,
      sopId: undefined,
      imeiArr: null,
      signed: false,
      newQuantityOfBuses: false,
      markerIndex: 0,
      count: 0,
      dataId: 0,
      deltaLat: 0,
      deltaLng: 0,
      locationCoord: {
        coords: {
          latitude: 49.44041,
          longitude: 32.06639
        }
      },
      lastSocketGeodata: null,
      addBusSocketData (data, route) {
        if (live.newQuantityOfBuses === true) {
          PageModel.variables.directionMarkers = []
          PageModel.variables.pngMarkers = []
        }
        live.newQuantityOfBuses = false
        let newData = {}
        for (let element in data) {
          newData[element] = data[element]
        }

        function isPositive (item) {
          return item.id === newData.id
        }

        if (PageModel.variables.pngMarkers.length < live.imeisLength && !PageModel.variables.pngMarkers.some(isPositive)) {
          PageModel.variables.pngMarkers.push(newData)
        }
        PageModel.variables.pngMarkers.forEach((e, index) => {
          if (e.id === data.id) {
            e.date = data.date
            e.direction = data.direction
            e.gtm = data.gtm
            e.id = data.id
            e.speed = data.speed
            e.type = data.type
            e.options = {
              zIndex: index + 10,
              optimized: false
            }
            e.icon = {
              scaledSize: {
                height: 34,
                width: 34
              },
              anchor: new google.maps.Point(17, 17)
            }
            if (index === 0) {
              e.icon = {
                scaledSize: {
                  height: 30,
                  width: 30
                },
                anchor: new google.maps.Point(15, 15)
              }
              if (route === 'trolleybus') {
                e.icon.url = './dist/images/trolleybus-first.png'
              } else {
                e.icon.url = './dist/images/marshrutka-first.png'
              }
            } else {
              if (route === 'trolleybus') {
                e.icon.url = './dist/images/trolleybus.png'
              } else {
                e.icon.url = './dist/images/marshrutka.png'
              }
            }
            if (!e.coords) {
              e.coords = {
                latitude: data.lat,
                longitude: data.lng
              }
            }
          }
        })
        if (PageModel.variables.directionMarkers.length < live.imeisLength && !PageModel.variables.directionMarkers.some(isPositive)) {
          PageModel.variables.directionMarkers.push(data)
        }
        PageModel.variables.directionMarkers.forEach((e, index) => {
          if (e.id === data.id) {
            e.date = data.date
            e.direction = data.direction
            e.gtm = data.gtm
            e.id = data.id
            e.speed = data.speed
            e.type = data.type
            e.optimized = false
            e.options = {
              zIndex: index + 10,
              optimized: false
            }
            e.icon = {
              path: adress.svg,
              fillColor: '#216A9F',
              strokeWeight: 0,
              fillOpacity: 1,
              scale: 1,
              rotation: data.direction
            }
            // if (index === 0) e.icon.fillColor = '#A6BE66'
            if (!e.coords) {
              e.coords = {
                latitude: data.lat,
                longitude: data.lng
              }
            } else {
              live.transition(data, e)
            }
          }
        })
      },
      // createCentralMarker (localMark, centralMarker) {
      //   console.log(PageModel.variables.stops.find(stop => PageModel.variables.stops[stop].id === localMark.id));
      //   // PageModel.variables.stops.find(stop => PageModel.variables.stops[stop].id === localMark.id)
      //   for (let stop in PageModel.variables.stops) {
      //     if (PageModel.variables.stops[stop].id === localMark.id) {
      //       centralMarker = PageModel.variables.stops[stop]
      //       console.log(centralMarker);
      //       return centralMarker
      //     }
      //   }
      // },
      createAllMarkers (coords, localMark, type) {
        let allMarkers = []
        let before = true
        // for (let coord = 0; coord < coords.length; coord++) {
        //   for (let stop = 0; stop < PageModel.variables.stops.length; stop++) {
        //     if (coords[coord].start === stop.id && !(type === 'markers' && localMark.id === PageModel.variables.stops[stop].id)) {
        //       if (before) {
        //         PageModel.variables.stops[stop].away = 'dark'
        //       } else {
        //         PageModel.variables.stops[stop].away = 'grey'
        //       }
        //       if (localMark.id === stop.id) {
        //         PageModel.variables.stops[stop].away = 'white'
        //         before = !before
        //       }
        //       allMarkers.push(PageModel.variables.stops[stop])
        //     }
        //   }
        // }
        for (let coord in coords) {
          for (let stop in PageModel.variables.stops) {
            if (type === 'markers') {
              if (localMark.id === PageModel.variables.stops[stop].id) {
                continue
              }
            }
            if (coords[coord].start === PageModel.variables.stops[stop].id) {
              if (before) {
                PageModel.variables.stops[stop].away = 'dark'
              } else {
                PageModel.variables.stops[stop].away = 'grey'
              }
              if (localMark.id === PageModel.variables.stops[stop].id) {
                PageModel.variables.stops[stop].away = 'white'
                before = !before
              }
              allMarkers.push(PageModel.variables.stops[stop])
            }
          }
        }
        return allMarkers
      },
      createAllMarkersList (coords, localMark) {
        return $q((resolve, reject) => {
          if (coords && localMark) {
            resolve(live.createAllMarkers(coords, localMark))
          } else {
            reject('Data error!')
          }
        })
      },
      findCoordinatesOfRoute (route) {
        let samples = []
        for (let i = 0; i < route.samples.length; i++) {
          for (let j = 0; j < $localStorage.dataCatch.sample.length; j++) {
            if (route.samples[i] === $localStorage.dataCatch.sample[j].id) {
              samples.push({
                id: $localStorage.dataCatch.sample[j].id,
                length: $localStorage.dataCatch.sample[j].length,
                end: $localStorage.dataCatch.sample[j].end,
                start: $localStorage.dataCatch.sample[j].start,
                path: $localStorage.dataCatch.sample[j].points.map(obj => {
                  return {
                    latitude: obj.lat,
                    longitude: obj.lng
                  }
                })
              })
            }
          }
        }
        return samples
      },
      findClosestBusesTime (routeObj, localMark) {
        let times = PageModel.socketStopsTimes.find(stopTime => stopTime.route === routeObj.id && stopTime.stop === localMark.id).times
        return times || false
        // for (let i = 0; i < PageModel.socketStopsTimes.length; i++) {
        //   if ((PageModel.socketStopsTimes[i].route === routeObj.id) && (PageModel.socketStopsTimes[i].stop === localMark.id)) {
        //     console.log(PageModel.socketStopsTimes[i].times);
        //     return PageModel.socketStopsTimes[i].times
        //   }
        // }
        // return false
      },
      finddirectionMarkers (closestBusesTime) {
        let imei = closestBusesTime.filter(bus => {
          return bus != null
        }).map(bus => {
          return bus.imei
        })
        live.imeisLength = imei.length
        if (!imei.length) {
          console.log('something wrong')
        }
        if (live.imeiArr === null) {
          live.imeiArr = imei
        }
        if (JSON.stringify(imei) === JSON.stringify(live.imeiArr) && !live.signed) {
          live.signed = true
          // socketService.geodata(live.imeiArr);
          newSocket.emit('subscribe', {'type': 'geodata', 'subject': live.imeiArr})
        }
      },
      getRoudById (id) {
        return $localStorage.dataCatch.routes.find(route => route.id === +id)
        // for (let route in $localStorage.dataCatch.routes) {
        //   if ($localStorage.dataCatch.routes[route].id === +id) {
        //     return $localStorage.dataCatch.routes[route]
        //   }
        // }
      },
      getStopById (id) {
        let stopReturn = PageModel.variables.stops.find(stop => stop.id === +id)
        stopReturn.show = false
        return stopReturn
        // for (let stop in PageModel.variables.stops) {
        //   if (PageModel.variables.stops[stop].id === +id) {
        //     PageModel.variables.stops[stop].show = false
        //     return PageModel.variables.stops[stop]
        //   }
        // }
      },
      moveMarker () {
        PageModel.timeouts.moveMarkerTimeout = $timeout(() => {
          if (PageModel.variables.directionMarkers[live.markerIndex]) {
            // coordinates of bus marker
            PageModel.variables.directionMarkers[live.markerIndex].coords.latitude += live.deltaLat
            PageModel.variables.directionMarkers[live.markerIndex].coords.longitude += live.deltaLng
            PageModel.variables.pngMarkers[live.markerIndex].coords.latitude += live.deltaLat
            PageModel.variables.pngMarkers[live.markerIndex].coords.longitude += live.deltaLng
            if (PageModel.variables.busClicked === true && PageModel.variables.activeModel.id === live.dataId) {
              // coordinates of window
              PageModel.variables.activeModel.latitude += live.deltaLat
              PageModel.variables.activeModel.longitude += live.deltaLng
            }
          }
        }, 0)
          .then(() => {
            if (live.count !== adress.numDeltas) {
              live.count++
              PageModel.timeouts.moveMarkerInsideTimeout = $timeout(() => {
                live.moveMarker()
                PageModel.clearForDynamicTimeouts(PageModel.timeouts.moveMarkerTimeout)
                PageModel.clearForDynamicTimeouts(PageModel.timeouts.moveMarkerInsideTimeout)
              }, adress.delay)
            }
          })
      },
      removedirectionMarkers () {
        // socketService.geodata([]);
        newSocket.emit('subscribe', {'type': 'geodata', 'subject': []})
        live.imeiArr = null
        live.signed = false
      },
      routeForBusNumber (id) {
        let numberBus = $localStorage.dataCatch.bus.find(bus => bus.id === id)
        if (numberBus) {
          return numberBus.number
        }
        // for (let bus in $localStorage.dataCatch.bus) {
        //   if (id === $localStorage.dataCatch.bus[bus].id) {
        //     return $localStorage.dataCatch.bus[bus].number
        //   }
        // }
      },
      // routeForBus (ridRoute, localMark) {
      //   return localMark.routes.find(route => parseInt(ridRoute) === parseInt(route))
      //   // for (let route = 0; route < localMark.routes.length; route++) {
      //   //   if (parseInt(ridRoute) === parseInt(localMark.routes[route])) {
      //   //     return localMark.routesName[route]
      //   //   }
      //   // }
      // },
      transition (data, bus) {
        live.markerIndex = PageModel.variables.directionMarkers.indexOf(bus)
        live.count = 0
        live.dataId = data.id
        live.deltaLat = (data.lat - bus.coords.latitude) / adress.numDeltas
        live.deltaLng = (data.lng - bus.coords.longitude) / adress.numDeltas
        live.moveMarker()
      },
      toggleSidebar () {
        PageModel.variables.showSidebar = !PageModel.variables.showSidebar
        angular.element($window.document.querySelector('.angular-google-map-container')).toggleClass('close')
        PageModel.resizeCounter = 0
        $rootScope.$broadcast('sidebarToggleEvent')
      },
      toggleLiveView (type) {
        PageModel.variables.checked = type
        PageModel.variables.activeModel.show = false
        if ($window.innerWidth <= 1120) {
          if (type === 'buses' || type === 'stops') {
            PageModel.variables.tall = false
            PageModel.variables.hiddenDiv = true
            PageModel.scrollIntoViewAnimated(PageModel.variables.scrollTo, 100, 0.83, 500)
          } else if (type === 'tall') {
            PageModel.variables.tall = !PageModel.variables.tall
            PageModel.variables.hiddenDiv = !PageModel.variables.hiddenDiv
            if (PageModel.variables.tall === false) {
              PageModel.variables.checked = 'buses'
              PageModel.variables.hiddenDiv = true
              PageModel.scrollIntoViewAnimated(PageModel.variables.scrollTo, 100, 0.83, 500)
            }
          }
        }
        if (type === 'stops' || type === 'tall') {
          $rootScope.$broadcast('functionToShowSmallSidebarListOfStops')
        } else {
          $rootScope.$broadcast('functionToCloseSmallSidebarListOfStops')
        }
      },
      windowManipulationForRouteClickEventOnTheMainMap (permission, id) {
        windowManipulation()

        function windowManipulation () {
          let routesLink = $window.document.getElementsByClassName('content-route')
          PageModel.timeouts.windowManipulationForRouteClickEventOnTheMainMapTimeout = $timeout(() => {
            for (let i = 0; i < routesLink.length; i++) {
              let stop = live.getStopById(id)
              for (let j = 0; j < stop.routesName.length; j++) {
                if (routesLink[i].classList[1] === `${stop.routesName[j]}-pressed`) {
                  permission = false
                  clickEventService.currentElement.model.options.icon.url = './dist/images/otherPin.png'
                  $rootScope.$broadcast('functionToOpenRouteAtSidebar', {
                    sid: id,
                    route: stop.routesName[j]
                  })
                  clickEventService.currentElement = {}
                  return
                }
              }
            }
            if (permission) {
              PageModel.timeouts.windowManipulationForRouteClickEventOnTheMainMapInsideTimeout = $timeout(() => {
                windowManipulation()
              }, 1000)
            } else {
            }
          }, 100)
        }
      }
    }
    return live
  })
export default moduleName
