/* global angular, angularSocketIO */
'use strict'

import io from 'socket.io-client'
import angularSocketIO from 'angular-socket-io'// eslint-disable-line

const moduleName = 'mapamagic.maps.MySocket'

angular.module(moduleName, [
  'btford.socket-io'
])
  .factory('newSocket', socketFactory => {
    'ngInject'
    let adress = require('../constants.js.dist')
    let ws = io.connect(adress.socketIoConnect, {secure: true})
    console.log(adress.socketIoConnect)
    return socketFactory({
      ioSocket: ws
    })
  })
  .value('version', '0.1')

export default moduleName
