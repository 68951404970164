/* global angular, ngStorage, */
'use strict'
import uuid from 'uuid'

import ngStorage from 'ngstorage'// eslint-disable-line

const moduleName = 'mapamagic.user.model'

angular.module(moduleName, ['ngStorage'])
  .factory('User', ($timeout, $http, $state, $localStorage, deviceDetector, GAuth, GApi, GData, socketService, PageModel) => {
    'ngInject'
    let adress = require('../base/constants.js.dist')
    let user = {
      os: deviceDetector.os,
      checkInputsValidation (fields) {
        let status = true
        fields.forEach(field => {
          if (!field.validator()) {
            status = false
          }
        })
        return status
      },
      registration (data, answer) {
        let dataSend = {
          user: {
            password: data.password,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            city: 'none',
            country: 'none',
            udid: uuid.v4(),
            socialNetwork: 'none',
            platformType: adress.platformTypeAll
          }
        }
        $localStorage.udid = dataSend.user.udid
        return $http.post(`${adress.siteLive}auth/register`, dataSend)
          .then((data) => {
            if (data.status === 200) {
              return data
            } else {
              throw data
            }
          })
          .catch((data) => {
            if (data.status === 400) {
              data.showError = 'subjectIsLoginMassege'
              throw data
            } else {
              data.showError = 'serverError'
              throw data
            }
          })
      },
      changeCity (data) {
        return $http({
          method: 'POST',
          url: `${adress.siteLive}user_profile/update/city`,
          headers: {
            'AUTH-KEY': $localStorage.userInfo.data.apikey
          },
          data
        })
          .then(response => {
            if (response.status === 200) {
              return response
            } else {
              throw response
            }
          })
          .catch(error => {
            throw error
          })
      },
      googleRegistration (data) {
        let dataSend = {
          user: {
            socialId: data.id,
            socialNetwork: 'google',
            email: data.email,
            firstName: data.given_name || 'none',
            lastName: data.family_name || 'none',
            udid: uuid.v4(),
            platformType: adress.platformTypeAll
          }
        }
        dataSend.user.country = dataSend.user.city = 'none'
        if (dataSend.user.email) {
          user.postingData(dataSend, 'auth/register')
        } else {
          user.extraAdd(dataSend)
        }
      },
      facebookRegistration (data) {
        let dataSend = {
          user: {
            socialId: data.id,
            socialNetwork: 'facebook',
            email: data.email,
            firstName: data.first_name,
            lastName: data.last_name,
            udid: uuid.v4(),
            platformType: adress.platformTypeAll
          }
        }
        dataSend.user.country = dataSend.user.city = 'none'
        if (dataSend.user.email) {
          user.postingData(dataSend, 'auth/register')
        } else {
          user.extraAdd(dataSend)
        }
      },
      login (login) {
        let dataSend = {
          login: {
            email: login.email,
            password: login.password,
            udid: uuid.v4(),
            platformType: adress.platformTypeAll
          }
        }
        $localStorage.udid = dataSend.login.udid
        return $http.post(`${adress.siteLive}auth/login`, dataSend)
          .then((data) => {
            if (data.status === 200) {
              return data
            } else {
              throw data
            }
          })
          .catch((data) => {
            if (data.status === 400) {
              data.showError = 'subjectIsLoginMassege'
              throw data
            } else {
              data.showError = 'serverError'
              throw data
            }
          })
      },
      fbLogin (provider, FB) {
        FB.getLoginStatus(function (response) {
          if (response.status === 'connected') {
            getFbData()
          } else {
            FB.login((data) => {
              if (data.authResponse) {
                getFbData(data)
              }
            }, {scope: 'email'})
          }
        })

        function getFbData (newData) {
          FB.api('/me?fields=email,first_name,last_name', userAccount => {
            userAccount.provider = provider
            user.socialSubmit(userAccount)
          })
        }
      },
      googleLogin (provider) {
        GAuth.login()
          .then((userAccount) => {
            userAccount.provider = provider
            user.socialSubmit(userAccount)
          }).catch((error) => console.error(error))
      },
      socialSubmit (socialLogin) {
        let dataSend = {
          socialLogin: {
            socialId: socialLogin.id,
            udid: uuid.v4(),
            socialNetwork: socialLogin.provider,
            platformType: adress.platformTypeAll
          }
        }
        $localStorage.udid = dataSend.socialLogin.udid
        user.postingData(dataSend, 'auth/sociallogin', socialLogin)
      },
      extraAdd (data) {
        let dataUserToSend = {}
        if (!data.email) {
          $localStorage.dataUserToSend = data
          $state.go('mapa.registration.post-registration')
          return
        }
        dataUserToSend = $localStorage.dataUserToSend
        if (!dataUserToSend.user.email) dataUserToSend.user.email = data.email
        dataUserToSend.country = 'none'
        dataUserToSend.city = 'none'
        $localStorage.udid = dataUserToSend.user.udid
        user.postingData(dataUserToSend, 'auth/register')
      },
      postingData (data, api, allData, controller) {
        $http.post(`${adress.siteLive}${api}`, data)
          .then((response) => {
            $localStorage.userInfo = response
            socketService.auth($localStorage.userInfo.data.apikey, () => { $timeout(() => $state.go('mapa.selectCity'), 500) })
          })
          .catch((error) => {
            if (allData && allData.provider) {
              if (allData.provider === 'google') {
                user.googleRegistration(allData)
              } else if (allData.provider === 'facebook') {
                user.facebookRegistration(allData)
              }
            } else {
              console.error('ERROR ', error)
            }
          })
      }
    }
    return user
  })

export default moduleName
