module.exports = {
    siteLive: 'https://admin.mapamagic.com/api/',
    client: '190194962614-1ba6ul0ua9c5tim15hp5p0jguq0bhj5g.apps.googleusercontent.com',
    googleScope: 'https://www.googleapis.com/auth/userinfo.email',
    mapsApikey: 'AIzaSyC0ZbTLp9Q-pjdr0fT9oLLuz_sMnNggYpA',
    facebookAppId: '216423658733543',
    socketIoConnect: 'https://admin.mapamagic.com:89', //http://beta-live-admin.mapamagic.com:8080 3001 // https://dc.ventmanager.com:89
    platformTypeAll: 'webapp-dev', //'webapp-prod'
    delay: 10,
    numDeltas: 100,
    svg: 'M-25,-25l0,50l0,-50ZM-25,25l50,0l-50,0ZM25,25l0,-50l0,50ZM25,-25l-50,0l50,0ZM-1.21,-24.41c0.478,-0.542 1.316,-0.596 1.86,-0.12l0.13,0.13l11.85,12.3c0,0 -5.85,-4.68 -12.73,-4.68c-6.88,0 -12.51,4.68 -12.51,4.68l11.4,-12.31ZM0,17.49c-0.003,0 -0.007,0 -0.01,0c-9.6,0 -17.5,-7.9 -17.5,-17.5c0,-9.6 7.9,-17.5 17.5,-17.5c9.6,0 17.5,7.9 17.5,17.5c0,0.003 0,0.007 0,0.01c-0.016,9.589 -7.901,17.474 -17.49,17.49l0,0ZM0,-14.61c-8.009,0 -14.6,6.591 -14.6,14.6c0,8.009 6.591,14.6 14.6,14.6c8.006,0 14.595,-6.584 14.6,-14.59c-0.005,-8.007 -6.593,-14.595 -14.6,-14.6l0,-0.01Z',
    arraysOfPropertiesForLoginAndRegistrationValidation: {
        login: ['email', 'password'],
        registration: ['email', 'password', 'city', 'country', 'firstName', 'lastName'],
        postRegistration: ['email', 'city', 'country']
    },
         citiesCoords: [
             {
                 cityId: 1,
                 coords: {
                     lat: 49.44041,
                     lng: 32.06639
                 }
             },
             {
                 cityId: 4,
                 coords: {
                     lat: 49.352751,
                     lng: 23.508155
                 }
             }
         ]
};
