/* global angular */
'use strict'

const moduleName = 'mapamagic.base.feedbackService'

angular.module(moduleName, [])
  .factory('feedbackService', ($localStorage, $http) => {
    'ngInject'
    let adress = require('../constants.js.dist')
    let feedback = {
      sendFeedbackServiceToTheServer (theme, feedback) {
        $http({
          method: 'POST',
          url: `${adress.siteLive}feedback/add`,
          headers: {
            'AUTH-KEY': $localStorage.userInfo.data.apikey
          },
          data: {
            'feedback': {
              theme: theme,
              message: feedback
            }
          }
        }).catch(error => console.error(error))
      }
    }
    return feedback
  })
export default moduleName
