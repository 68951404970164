/* global angular, ngclipboard, event */
'use strict'
import ngclipboard from 'ngclipboard'// eslint-disable-line

const moduleName = 'mapamagic.maps.sidebar'

angular.module(moduleName, ['nemLogging', 'uiGmapgoogle-maps', '720kb.socialshare', 'ngclipboard'])
  .controller('SidebarController', function ($window, $document, $rootScope, $q, $interval, $timeout, $localStorage, uiGmapIsReady, Mark, socketService, PageModel, liveService, favouriteService, clickEventService) {
    'ngInject'
    if (!$localStorage.userInfo) return false

    let vm = this
    vm.question = {
      popup: false,
      remove: false
    }
    vm.statusOfFavouriteStop = {}
    vm.counterForFavouriteStopsCheck = 0
    vm.canOpenRoute = true
    vm.permission = PageModel.permissionForTransport
    vm.linePermissionForStopAtTheList = PageModel.linePermissionForTransport
    vm.permissionForShowListOfStops = $window.innerWidth <= 1120
    vm.route = {}
    vm.showSidebarLists = {}
    vm.showSpinner = false
    vm.sidebarShow = [false, false, false]

    if (!vm.variables || vm.variables.stops.length === 0) {
      vm.variables = PageModel.variables // object of variables that used at map page
    }
    favouriteService.checkFavouritesFromServer()
    // checkFavouriteStops()
    // functions that swipe up and down sidebar on mobile devices
    vm.DragUp = function () {
      if ($window.innerWidth <= 1120 && PageModel.isMobile.any()) {
        vm.variables.showSidebar = false
      }
    }
    vm.DragDown = function () {
      if ($window.innerWidth <= 1120 && PageModel.variables.inside && PageModel.variables.checked !== 'buses' && PageModel.isMobile.any()) {
        liveService.toggleLiveView('buses')
      } else if ($window.innerWidth <= 1120 && PageModel.isMobile.any()) {
        vm.variables.showSidebar = true
      }
    }

    // function that toggle sidebar
    vm.toggleSidebar = function () {
      liveService.toggleSidebar()
      // hideClosestStopsRemove(null, 'all')
    }

    // a function that opens a list of the routes stop.
    vm.closeAllStops = {}
    vm.toggleWrapper = function (elementEvent, stop, name, index) {
      elementEvent.stopPropagation()
      angular.element(elementEvent.currentTarget)[0].nextElementSibling.classList.toggle('active')
      if (name) {
        angular.element(elementEvent.currentTarget.children[name]).toggleClass('active')
      } else {
        angular.element(elementEvent.currentTarget).toggleClass('active')
      }
      if (index || index === 0) {
        vm.sidebarShow[index] = !vm.sidebarShow[index]
      }
      if ($window.innerWidth > 1120) {
        // if (index || index === 0) {
        //   vm.sidebarShow[index] = !vm.sidebarShow[index]
        // }
        console.log(vm.sidebarShow)
        PageModel.scrollIntoViewAnimated('' + stop.id, 400, 0, 100)
        // vm.showButton[`showButtonFavourite${stop.id}`] = !vm.showButton[`showButtonFavourite${stop.id}`];
        if (vm.closeAllStops[`stop${stop.id}`]) {
          vm.closeAllStops[`stop${stop.id}`] = true
        } else {
          hideClosestStopsRemove(stop)
          vm.closeAllStops[`stop${stop.id}`] = false
        }
      }
    }
    // for tabs to switch
    vm.tabChange = value => {
      if (value === 'Second') {
        vm.counterForFavouriteStopsCheck++
      }
      if (vm.counterForFavouriteStopsCheck === 4) {
        favouriteService.checkFavouritesFromServer()
        vm.counterForFavouriteStopsCheck = 0
      }
      vm.permissionForShowListOfStops = $window.innerWidth <= 1120
      vm.variables.tab = value
      hideClosestStopsRemove(null, 'all')
      PageModel.variables.windowOptions.content = ''
      PageModel.variables.windowOptions.boxStyle.display = 'none'
      PageModel.variables.windowOptions.boxStyle.width = PageModel.variables.windowOptions.boxStyle.height = '0px'
      vm.showSidebarLists = {}
      if (vm.variables.showClosestStops === true) $rootScope.$broadcast('tabChangeEvent')
      // if ($window.innerWidth <= 1120) vm.variables.showSidebar = false
    }

    // function to add favourite stop
    vm.addFavouriteStop = (id, event) => {
      event.stopPropagation()
      if (vm.statusOfDeleteFavouriteStop === id) {
        return
      }
      $interval.cancel(vm.variables.timer)
      vm.variables.timer = null
      PageModel.variables.favouriteClick = true
      vm.statusOfFavouriteStop[id] = true
      vm.blink = true
      let blinkTimeout = $timeout(() => {
        vm.blink = false
        PageModel.clearForDynamicTimeouts(blinkTimeout)
      }, 1000)
      let favouriteServiceTimeout = $timeout(() => {
        favouriteService.checkIfAppUserHaveThatFavouriteStop()
        PageModel.clearForDynamicTimeouts(favouriteServiceTimeout)
      }, 0)
      favouriteService.addFavouriteInAppWithoutServerChecking('add', id)
      $localStorage.userInfo.data.favouriteStops.push({busStop: id})
      favouriteService.addFavouriteStopsToServer(id)
        .then(() => {
          PageModel.variables.favouriteClick = false
          PageModel.sidebarFavouriteCheckTimeout = $timeout(() => {
            favouriteService.checkFavouritesFromServer()
          }, 5000)
          // checkFavouriteStops()
          vm.statusOfFavouriteStop[id] = false
          angular.element(event.target).toggleClass('active')
          angular.element(event.target).next().toggleClass('active')
        }).catch(error => console.error(error))
    }
    // function to remove favourite stop
    vm.questionRemoveFavouriteStop = (id, event, open) => {
      if (!$localStorage.questionPopup) $localStorage.questionPopup = {}
      if (event && event !== null) event.stopPropagation()
      vm.question.popup = true
      if (id && id !== null) $localStorage.questionPopup.id = id
      if (open) removeFavouriteStop($localStorage.questionPopup.id)
    }

    function removeFavouriteStop (id) {
      $interval.cancel(vm.variables.timer)
      vm.variables.timer = null
      PageModel.variables.favouriteClick = true
      vm.statusOfDeleteFavouriteStop = id
      vm.question.popup = vm.question.remove = false
      angular.element(event.target).toggleClass('active')
      favouriteService.addFavouriteInAppWithoutServerChecking('rm', id)
      let favouriteServiceTimeout = $timeout(() => {
        favouriteService.checkIfAppUserHaveThatFavouriteStop()
        PageModel.clearForDynamicTimeouts(favouriteServiceTimeout)
      }, 0)
      $localStorage.userInfo.data.favouriteStops.forEach((element, index) => {
        if (element.busStop === id) $localStorage.userInfo.data.favouriteStops.splice(index, 1)
      })
      favouriteService.deleteFavouriteStopsFromServer(id)
        .then(() => {
          PageModel.variables.favouriteClick = false
          PageModel.sidebarFavouriteCheckTimeout = $timeout(() => {
            favouriteService.checkFavouritesFromServer()
          }, 5000)
          // checkFavouriteStops()
          vm.statusOfDeleteFavouriteStop = undefined
        })
        .catch(error => console.error(error))
    }

    // properties and methods for route view
    vm.localMark = {} // options of the central marker
    vm.allMarkersList = [] // array (list) of all stops on the route
    vm.variables.windowOptions = Mark.windowOptions // InfoWindow options
    // events for markers
    vm.variables.markersEvents = {
      click (marker, eventName, model) {
        clickEventService.clickEvent(marker, eventName, model, liveService.routeForBusNumber(model.idKey)) // I just send all what was here to model.js
      }
    }
    // a function that is called when pressing on the route
    let startCoords
    vm.routeClick = (rid, sid, elementIndex, elementEvent, type, name) => {
      PageModel.variables.windowOptions.content = ''
      PageModel.variables.windowOptions.boxStyle.display = 'none'
      PageModel.variables.windowOptions.boxStyle.width = PageModel.variables.windowOptions.boxStyle.height = '0px'
      vm.rid = rid
      vm.showReturnButtonOnTheListOfRoutes = {}
      vm.showReturnButtonOnTheListOfRoutes[`${type}.${sid}`] = true
      socketService.route = vm.route
      socketService.type = type
      if (vm.showSidebarLists[`show.${type}.${sid}.${elementIndex}`] && elementEvent) {

      } else if (vm.canOpenRoute) {
        vm.canOpenRoute = false
        vm.variables.showMapSpinner = true
        vm.showSidebarLists = {}
        // vm.showReturnButtonOnTheListOfRoutes = {}
        Mark.map.zoom = 13
        vm.selectedElement = {
          sid: sid,
          index: elementIndex,
          type: type
        }
        if (elementEvent) elementEvent.stopPropagation()
        if (vm.variables.activeModel) vm.variables.activeModel.show = false
        // vm.showReturnButtonOnTheListOfRoutes[`${type}.${sid}`] = true
        if (vm.showSidebarLists[`show.${type}.${sid}.${elementIndex}`] === undefined) {
          vm.showSidebarLists[`show.${type}.${sid}.${elementIndex}`] = {
            permission: false,
            sid: sid,
            type: type,
            index: elementIndex
          }
        }
        vm.showSidebarLists = PageModel.changeShowSidebarListsValue(sid, elementIndex, type, vm.showSidebarLists)
        showClosestStopsRemove(sid, elementIndex, type)
        if (vm.showSidebarLists[`show.${type}.${sid}.${elementIndex}`].permission) {
          angular.element($window.document.querySelectorAll('.route_name')).removeClass('active')
          PageModel.rewriteTheMainRouteClass()
          PageModel.variables.inside = true
          startCoords = PageModel.variables.center.coords
          if ($window.innerWidth <= 1120) {
            PageModel.scrollIntoViewAnimated(`${type}.${sid}.${elementIndex}`, 100, 0.5, 500)
            vm.variables.showSidebar = true
            vm.variables.hiddenDiv = true
          }
          vm.variables.showClosestStops = false
          liveService.routeId = vm.rid = rid
          liveService.sopId = vm.sid = sid
          let showSpinnerTimeout = $timeout(() => {
            vm.showSpinner = true
            PageModel.clearForDynamicTimeouts(showSpinnerTimeout)
          }, 500)
          vm.selectedElement.currentElement = PageModel.findElementAtTheView(vm.variables.tab, sid, type, elementIndex)
          if (vm.selectedElement.currentElement) {
            console.log(vm.selectedElement.currentElement)
            vm.selectedElement.currentElement.className = `route_name ${sid}-pressed active`
          } else {
            $window.location.reload()
          }
          $rootScope.$broadcast('viewChangeEvent')
          vm.route = liveService.getRoudById(rid)
          vm.variables.coords = liveService.findCoordinatesOfRoute(vm.route)
          vm.variables.centralMarker = liveService.getStopById(sid)
          vm.variables.centralMarker.options.zIndex = 9
          vm.variables.centralMarker.options.optimized = false
          for (let coord in vm.variables.centralMarker.coords) {
            vm.variables.center.coords[coord] = vm.variables.centralMarker.coords[coord]
          }
          vm.variables.allMarkers = liveService.createAllMarkers(vm.variables.coords, vm.variables.centralMarker, 'markers')
          vm.closestBusesTime = liveService.findClosestBusesTime(vm.route, vm.variables.centralMarker)
          liveService.finddirectionMarkers(vm.closestBusesTime)
          vm.allMarkersList = liveService.createAllMarkers(PageModel.variables.coords, vm.variables.centralMarker)
          liveService.createAllMarkersList(PageModel.variables.coords, vm.variables.centralMarker)
            .then((data) => {
              vm.allMarkersList = data
            })
            .finally(() => {
              vm.showSidebarLists[`show.${type}.${sid}.${elementIndex}`].permission = true
              let createAllMarkersListFinallyTimeout = $timeout(() => {
                vm.canOpenRoute = true
                vm.variables.showMapSpinner = false
                PageModel.clearForDynamicTimeouts(createAllMarkersListFinallyTimeout)
              }, 1000)
              // vm.showSpinner = false;
              if ($window.innerWidth <= 1120) vm.variables.scrollTo = $window.document.getElementById(`${vm.selectedElement.type}.${vm.selectedElement.sid}${vm.selectedElement.index}`)
            })
            .catch((error) => console.error(error))
        } else {
          let falsePermissionTimeout = $timeout(() => {
            vm.canOpenRoute = true
            vm.variables.showMapSpinner = false
            PageModel.clearForDynamicTimeouts(falsePermissionTimeout)
          }, 1000)
          if ($window.innerWidth <= 1120) {
            vm.variables.tall = false
            vm.variables.showSidebar = false
          }
          PageModel.variables.center.coords = startCoords
          $rootScope.$broadcast('viewChangeEvent')
        }
      }
    }
    vm.closePopupQuestion = () => {
      vm.question.popup = false
    }
    vm.styleControl = (command, event) => {
      event.stopPropagation()
      vm.wrapperScroll = command === 'inside'
    }

    function hideClosestStopsRemove (stop, task) {
      PageModel.variables.inside = false
      Mark.map.zoom = 15
      if (vm.selectedElement) PageModel.showLinePermissionsForLastStopAtTheSidebarList(vm.selectedElement.type, vm.selectedElement.sid, 'hideAll')
      vm.showMessage = PageModel.permissionToDrawBuble = false
      if (clickEventService.currentElement.model) {
        clickEventService.currentElement.model.show = false
      }
      let permissionForCloseStopList = false
      for (let list in vm.showSidebarLists) {
        if (task || vm.propertyOnClickOnBubleForCloseListOfRoutes) {
          if (vm.showSidebarLists[list].permission) {
            vm.showSidebarLists[list].permission = !vm.showSidebarLists[list].permission
            permissionForCloseStopList = true
            vm.propertyOnClickOnBubleForCloseListOfRoutes = false
          }
        } else if (vm.showSidebarLists[list].sid === '' + stop.id && vm.showSidebarLists[list].permission) {
          vm.showSidebarLists[list].permission = !vm.showSidebarLists[list].permission
          permissionForCloseStopList = true
        }
      }
      for (let property in vm.showReturnButtonOnTheListOfRoutes) {
        vm.showReturnButtonOnTheListOfRoutes[property] = false
      }
      if (permissionForCloseStopList) {
        if (vm.variables.activeModel) vm.variables.activeModel.show = false
        vm.allMarkersList = []
        PageModel.count = PageModel.numDeltas
        vm.variables.directionMarkers = []
        vm.variables.pngMarkers = []
        vm.variables.showClosestStops = !vm.variables.showClosestStops
        angular.element($window.document.querySelectorAll('.route_name')).removeClass('active')
        PageModel.rewriteTheMainRouteClass()
        PageModel.closeListOfStopsByClick()
        liveService.removedirectionMarkers()
        if ($window.innerWidth <= 1120) {
          vm.variables.tall = false
          vm.variables.showSidebar = false
        }
        $rootScope.$broadcast('viewChangeEvent')
      }
      vm.showSidebarLists = {}
    }

    function showClosestStopsRemove (sid, elementIndex, type) {
      PageModel.variables.inside = false
      vm.allMarkersList = []
      vm.variables.directionMarkers = []
      vm.variables.pngMarkers = []
      vm.variables.showClosestStops = !vm.variables.showClosestStops
      PageModel.count = PageModel.numDeltas
      PageModel.closeListOfStopsByClick()
      PageModel.showLinePermissionsForLastStopAtTheSidebarList(vm.selectedElement.type, vm.selectedElement.sid)
      liveService.removedirectionMarkers()
      PageModel.scrollIntoViewAnimated(`${type}.${sid}.${elementIndex}`, 100, 0.5, 500)
    }

    // function checkFavouriteStops () {
    //   if ($localStorage.userInfo) {
    //     favouriteService.checkFavouritesFromServer()
    //     if (vm.variables.timer === null) {
    //       vm.variables.timer = $interval(() => {
    //         favouriteService.checkFavouritesFromServer()
    //       }, 10000)
    //     }
    //   }
    // }
    vm.returnToMainMap = () => hideClosestStopsRemove(null, 'all')
    // vm.closeListOfStopsByClickOnButtonOnTheDownOfList = () => $rootScope.$broadcast('functionToCloseSmallSidebarListOfStops')
    $rootScope.$on('functionToShowSmallSidebarListOfStops', () => {
      if (!vm.permissionForShowListOfStops) {
        console.log(vm.selectedElement.currentElement)
        angular.element(vm.selectedElement.currentElement)[0].parentElement.children[1].className = 'wrapper-live-stops-list-open'
        PageModel.showLinePermissionsForLastStopAtTheSidebarList(vm.selectedElement.type, vm.selectedElement.sid, 'show')
        let closeListOfStopsByClickOnButtonOnTheDownOfListTimeout = $timeout(() => {
          // if ($window.innerWidth <= 1120) {
          //   vm.showSpinner = false
          //   PageModel.scrollIntoViewAnimated(`${vm.selectedElement.type}.${vm.selectedElement.sid}.${vm.selectedElement.index}`, 100, 0.83, 500)
          // } else {
          PageModel.scrollIntoViewAnimated(`${vm.selectedElement.type}.white.${vm.selectedElement.sid}.${vm.selectedElement.index}`, 100, 0.5, 500)
          // }
          PageModel.clearForDynamicTimeouts(closeListOfStopsByClickOnButtonOnTheDownOfListTimeout)
        }, 500)
      }
    })
    $rootScope.$on('functionToCloseSmallSidebarListOfStops', () => {
      PageModel.closeListOfStopsByClick()
      PageModel.showLinePermissionsForLastStopAtTheSidebarList(vm.selectedElement.type, vm.selectedElement.sid)
    })
    $rootScope.$on('functionToCloseSidebarListOfStops', () => hideClosestStopsRemove(null, 'all'))
    $rootScope.$on('functionToOpenRouteAtSidebar', (event, data) => {
      PageModel.clearForDynamicTimeouts(PageModel.timeouts.windowManipulationForRouteClickEventOnTheMainMapTimeout)
      PageModel.clearForDynamicTimeouts(PageModel.timeouts.windowManipulationForRouteClickEventOnTheMainMapInsideTimeout)
      data = PageModel.getTypeAndIndexFromWithSocketData(data)
      vm.propertyOnClickOnBubleForCloseListOfRoutes = true
      vm.routeClick(data.rid, data.sid, data.index, undefined, data.type)
    })
    // vm.dataToRewrite =
    $rootScope.$on('rewriteListOfStopsAndFindWeNeededStopInList', () => {
      PageModel.rewriteListOfStopsAndFindWeNeededStopInList(vm.rid)
    })
  })

export default moduleName
