/* global angular */
'use strict'

const moduleName = 'mapamagic.base.filter.buses'

angular.module(moduleName, [])
  .filter('busesFilter', function () {
    'ngInject'
    return function (item) {
      let array = []
      if (item) {
        item.forEach((element, index) => {
          if (element.type === 2 || element.type === 3) {
            array.push(element)
          }
        })
      }
      return array
    }
  })

export default moduleName
