/* global google */
'use strict'

import angular from 'angular'
import angularUiRouter from 'angular-ui-router'
import google from 'angular-google-gapi'// eslint-disable-line
import main from '../base/mainController'
import login from '../users/loginController'
import registration from '../users/registrationController'
import pRegistration from '../users/post-registr/post-registrationController'
import selectCity from '../users/selectCityController'
import page from '../maps/pageController'
import header from '../maps/partials/headerController'
import map from '../maps/partials/mapController'
import sidebar from '../maps/partials/sidebarController'

const parentTemplate = './app/partials/parent.html'
const loginTemplate = './app/partials/users/login.html'
const registrationTemplate = './app/partials/users/registration.html'
const postRegistrationTemplate = './app/partials/users/post-registr/post-registration.html'
const selectCityTemplate = './app/partials/users/selectCity.html'
const pageTemplate = './app/partials/maps/page.html'
const sidebarTemplate = './app/partials/maps/partials/sidebar.html'
const headerTemplate = './app/partials/maps/partials/header.html'
const mapTemplate = './app/partials/maps/partials/map.html'

const moduleName = 'mapamagic.base.router'

angular.module(moduleName, [
  angularUiRouter,
  'angular-google-gapi',
  main,
  login,
  registration,
  pRegistration,
  selectCity,
  page,
  header,
  map,
  sidebar
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject'
    $stateProvider
      .state('mapa', {
        url: '/mapa',
        abstract: true,
        controller: 'MainController as Main',
        templateUrl: parentTemplate
      })
      .state('mapa.login', {
        url: '/login',
        controller: 'LoginController as Login',
        templateUrl: loginTemplate
      })
      .state('mapa.registration', {
        url: '/registration',
        controller: 'RegistrationController as Registration',
        templateUrl: registrationTemplate
      })
      .state('mapa.registration.post-registration', {
        url: '/post-registration',
        controller: 'PostRegistrationController as PostRegistration',
        templateUrl: postRegistrationTemplate
      })
      .state('mapa.selectCity', {
        url: '/select-city',
        controller: 'SelectCityController as SelectCity',
        templateUrl: selectCityTemplate
      })
      .state('mapa.page', {
        url: '/page',
        views: {
          '': {
            controller: 'PageController as Page',
            templateUrl: pageTemplate
          },
          'header@mapa.page': {
            controller: 'HeaderController as Header',
            templateUrl: headerTemplate
          },
          'map@mapa.page': {
            controller: 'MapController as Map',
            templateUrl: mapTemplate
          },
          'sidebar@mapa.page': {
            controller: 'SidebarController as Sidebar',
            templateUrl: sidebarTemplate
          }
        }
      })
    $urlRouterProvider.otherwise(($injector) => {
      return $injector.get('$localStorage').userInfo ? '/mapa/page' : '/mapa/login'
    })
  })

export default moduleName
