/* global angular, FB */
'use strict'

const moduleName = 'mapamagic.user.login'

angular.module(moduleName, ['ng.deviceDetector'])
  .controller('LoginController', function ($window, $localStorage, $timeout, $state, deviceDetector, User, PageModel, newSocket, socketService, Validation) {
    'ngInject'
    let vm = this
    vm.os = User.os
    if (PageModel.variables.stops.length !== 0) {
      PageModel.logout()
    }
    vm.closeDownload = () => {
      User.os = vm.os = 'other'
    }
    vm.inputFields = [
      {
        name: 'email',
        value: null,
        controllerType: 'login',
        inputTypeForClassMessage: 'email',
        placeholder: 'Електронна пошта',
        validator: Validation.validateEmail,
        text: 'Імейл має бути у форматі yourmail@xxxx.xx',
        modelValue: null,
        showMessage: false,
        submitted: false
      }, {
        name: 'password',
        value: null,
        controllerType: 'login',
        inputTypeForClassMessage: 'email',
        placeholder: 'Пароль',
        validator: Validation.validatePassword,
        text: 'Пароль має вміщувати більше 8 символів.',
        modelValue: null,
        showMessage: false,
        submitted: false
      }
    ]
    $window.document.onkeyup = event => {
      event = event || $window.event
      if (event.keyCode === 13) {
        vm.submit()
        vm.inputFields.forEach(field => {
          field.submitted = true
        })
      };
      return false
    }

    vm.variables = PageModel.variables
    vm.closeErrorMessage = (data, status, event, validationResult) => {
      event.stopPropagation()
      if (!validationResult) {
        if (status || data === 'closeAll') {
          vm.inputFields.forEach(field => {
            field.showMessage = field.name === data
          })
        }
      } else {
        vm.inputFields.forEach(field => {
          field.showMessage = false
        })
      }
    }
    vm.submit = () => { // submit with email
      let dataLogin = {}
      vm.inputFields.forEach(field => {
        field.submitted = true
        dataLogin[field.name] = field.value
      })
      if (User.checkInputsValidation(vm.inputFields)) {
        User.login(dataLogin)
          .then((response) => {
            if (response) {
              $localStorage.newData = {}
              $localStorage.userInfo = response
              PageModel.variables.showSpinner = PageModel.variables.smoothShowSpinner = true
              socketService.auth($localStorage.userInfo.data.apikey, () => { $timeout(() => $state.go('mapa.page'), 500) })
            }
          })
          .catch((error) => {
            vm[error.showError] = true
            console.error(error)
          })
      }
    }
    vm.authenticate = provider => { // submit with social network
      console.log(provider)
      if (provider === 'google') {
        User.googleLogin(provider)
      } else if (provider === 'facebook') {
        User.fbLogin(provider, FB)
      }
    }
    vm.closeErrorMassageAfterAnswerFromServer = value => {
      vm[value] = !vm[value]
    }
    vm.reload = () => $window.location.reload()
  })

export default moduleName
