/* global angular */
'use strict'

const moduleName = 'mapamagic.base.actionService'

angular.module(moduleName, [])
  .factory('actionService', ($localStorage, $rootScope, $timeout, liveService, newSocket, PageModel) => {
    'ngInject'

    let action = {
      logout () {
        PageModel.logout()
      },
      route: [],
      type: 'bus',
      processingGeodataFromSocketService (data, route, type) {
        action.route = route
        action.type = type
        if (!$localStorage.userInfo) {
          action.logout()
        }
        // if bus disappear window for this bus will disappear to
        PageModel.timeouts.processingGeodataFromSocketServiceTimeout = $timeout(() => {
          if (PageModel.variables.busClicked && liveService.imeiArr.indexOf(PageModel.variables.activeModel.id) === -1) {
            PageModel.variables.activeModel.show = false
            PageModel.variables.busClicked = false
          }
        }, 0)
          .then(() => {
            PageModel.clearForDynamicTimeouts(PageModel.timeouts.processingGeodataFromSocketServiceTimeout)
            liveService.lastSocketGeodata = data
            liveService.addBusSocketData(data, type)
          })
      },
      processingStopsDataFromSocketService (data, route, type) {
        if (liveService.lastSocketGeodata !== null) {
          liveService.addBusSocketData(liveService.lastSocketGeodata, action.route.length === 0 ? {} : action.type)
        }
        if (!$localStorage.userInfo) {
          action.logout()
        }
        let socketData = PageModel.rewriteData(data.routes)
        let currentImeiArr = []
        let stopsInfo = socketData.filter(stop => {
          return stop != null
        }).map(stop => {
          return {
            currentRoute: stop.route,
            currentStop: stop.stop,
            imeiArr: stop.times.map(obj => {
              return obj.imei
            })
          }
        })
        stopsInfo.forEach(e => {
          if (liveService.routeId === e.currentRoute && +liveService.sopId === e.currentStop) {
            currentImeiArr = e.imeiArr
            if (liveService.imeiArr && currentImeiArr && liveService.imeiArr.sort().toString() !== currentImeiArr.sort().toString()) {
              liveService.newQuantityOfBuses = true
              liveService.imeiArr = currentImeiArr
              liveService.imeisLength = liveService.imeiArr.length
              newSocket.emit('subscribe', {'type': 'geodata', 'subject': liveService.imeiArr})
            }
          }
        })
        if (socketData.length > 0) {
          PageModel.getSocketDataForStorageFromSocket(socketData)
          PageModel.rewriteStops(socketData, route)
        } else {
          console.info('There is no socket data from server!')
        }
      }
    }
    return action
  })
export default moduleName
