/* global angular, FB */
'use strict'

const moduleName = 'mapamagic.user.registration'

angular.module(moduleName, [])
  .controller('RegistrationController', function ($window, $localStorage, $timeout, $state, newSocket, socketService, User, Validation, PageModel) {
    'ngInject'
    let vm = this
    vm.os = User.os
    if (PageModel.variables.stops.length !== 0) {
      PageModel.logout()
    }
    vm.closeDownload = () => {
      User.os = vm.os = 'other'
    }
    vm.inputFields = [
      {
        name: 'firstName',
        value: null,
        controllerType: 'registration',
        inputTypeForClassMessage: 'other',
        placeholder: `Ім'я`,
        validator: Validation.validateOther,
        text: 'Ви не ввели дані.',
        modelValue: null,
        showMessage: false,
        submitted: false
      }, {
        name: 'lastName',
        value: null,
        controllerType: 'registration',
        inputTypeForClassMessage: 'other',
        placeholder: 'Прізвище',
        validator: Validation.validateOther,
        text: 'Ви не ввели дані.',
        modelValue: null,
        showMessage: false,
        submitted: false
      }, {
        name: 'email',
        value: null,
        controllerType: 'registration',
        inputTypeForClassMessage: 'email',
        placeholder: 'Електронна пошта',
        validator: Validation.validateEmail,
        text: 'Імейл має бути у форматі yourmail@xxxx.xx',
        modelValue: null,
        showMessage: false,
        submitted: false
      }, {
        name: 'password',
        value: null,
        controllerType: 'registration',
        inputTypeForClassMessage: 'email',
        placeholder: 'Пароль',
        validator: Validation.validatePassword,
        text: 'Пароль має вміщувати більше 8 символів.',
        modelValue: null,
        showMessage: false,
        submitted: false
      }
    ]
    vm.closeErrorMessage = (data, status, event, validationResult) => {
      event.stopPropagation()
      if (!validationResult) {
        if (status || data === 'closeAll') {
          vm.inputFields.forEach(field => {
            field.showMessage = field.name === data
          })
        }
      } else {
        vm.inputFields.forEach(field => {
          field.showMessage = false
        })
      }
    }
    vm.registrationUser = () => {
      let dataLogin = {}
      vm.inputFields.forEach(field => {
        field.submitted = true
        dataLogin[field.name] = field.value
      })
      // Vitalic doesn't make server be able to get empty string
      dataLogin.city = 'Vitailc'
      dataLogin.country = 'Sdelay post'
      if (User.checkInputsValidation(vm.inputFields)) {
        User.registration(dataLogin)
          .then((response) => {
            if (response) {
              $timeout(() => {
                $localStorage.newData = {}
                $localStorage.userInfo = response
                $state.go('mapa.selectCity')
              }, 0)
            }
          })
          .catch((error) => {
            vm[error.showError] = true
            console.error(error)
          })
      }

    }
    vm.authenticate = provider => { // submit with social network
      if (provider === 'google') {
        User.googleLogin(provider)
      } else if (provider === 'facebook') {
        User.fbLogin(provider, FB)
      }
    }
    vm.closeErrorMassageAfterAnswerFromServer = value => {
      vm[value] = !vm[value]
    }
    $window.document.onkeyup = event => {
      event = event || $window.event
      if (event.keyCode === 13) {
        vm.registrationUser()
        vm.inputFields.forEach(field => {
          field.submitted = true
        })
      }
      return false
    }
    vm.reload = () => $window.location.reload()
  })

export default moduleName
