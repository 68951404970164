/* global angular */
'use strict'

const moduleName = 'mapamagic.base.favouriteService'

angular.module(moduleName, [])
  .factory('favouriteService', ($window, $localStorage, $http, PageModel) => {
    'ngInject'
    let adress = require('../constants.js.dist')
    let favourites = {
      addFavouriteStopsToServer (stopId) {
        return $http({
          method: 'POST',
          url: `${adress.siteLive}favourite/busstop/add`,
          headers: {
            'AUTH-KEY': $localStorage.userInfo.data.apikey
          },
          data: {
            'favouriteStop': {
              'busStop': stopId
            }
          }
        }).catch(error => console.error(error))
      },
      deleteFavouriteStopsFromServer (id) {
        return $http.delete(`${adress.siteLive}favourite/busstop/remove/${id}`, {headers: {'AUTH-KEY': $localStorage.userInfo.data.apikey}})
          .catch(error => console.error(error))
      },
      addFavouriteInAppWithoutServerChecking (type, id) {
        PageModel.variables.stops.find(stop => stop.id === id).favouriteStop = (type === 'add')
        // PageModel.variables.stops.forEach(stop => {
        //   if (stop.id === id) {
        //     stop.favouriteStop = (type === 'add')
        //     return false
        //   }
        // })
        favourites.createStopsIdForFavouriteTabOnTheMap() // stopsIdArr - array of very closest stops id for socket emmit + favourite stops id
      },
      createStopsIdForFavouriteTabOnTheMap () {
        PageModel.variables.stopsIdArr = []
        PageModel.variables.stops.forEach((stop, index) => {
          if (stop.added === true ||
              stop.favouriteStop === true ||
              index === 0 || index === 1 || index === 2) {
            PageModel.variables.stopsIdArr.push(stop.id)
          }
          // if (stop.favouriteStop === true) {
          //   PageModel.variables.stopsIdArr.push(stop.id)
          // }
          // if (index === 0 || index === 1 || index === 2) {
          //   PageModel.variables.stopsIdArr.push(stop.id)
          // }
        })
        PageModel.variables.stopsIdArr = PageModel.variables.stopsIdArr.filter((element, index, self) => {
          return index === self.indexOf(element)
        })
      },
      checkIfAppUserHaveThatFavouriteStop () {
        function isFavourite (e) {
          return e.favouriteStop === true
        }
        if (PageModel.variables.stops.some(isFavourite)) {
          PageModel.variables.showNotification = false
        } else {
          PageModel.variables.showNotification = true
        }
      },
      checkFavouritesFromServer () {
        $http({
          method: 'GET',
          url: `${adress.siteLive}favourite/busstop/list/${$localStorage.userInfo.data.id}`,
          headers: {
            'AUTH-KEY': $localStorage.userInfo.data.apikey
          }
        })
          .then(favourite => {
            if (!PageModel.variables.favouriteClick) {
              if ($localStorage.userInfo.data.favouriteStops.length > favourite.data.length) {
                for (let stop = 0; stop < PageModel.variables.stops.length; stop++) {
                  PageModel.variables.stops[stop].favouriteStop = false
                }
                // for (let stop in PageModel.variables.stops) {
                //   PageModel.variables.stops[stop].favouriteStop = false
                // }
                favourites.putFavouriteValue(favourite)
              } else {
                favourites.putFavouriteValue(favourite)
              }
              clearInterval(PageModel.sidebarFavouriteCheckTimeout)
              $localStorage.userInfo.data.favouriteStops = favourite.data
              favourites.checkIfAppUserHaveThatFavouriteStop()
            }
          })
          .catch(error => console.error(error))
      },
      putFavouriteValue (favourite) {
        for (let stop = 0; stop < PageModel.variables.stops.length; stop++) {
          for (let favouriteStop = 0; favouriteStop < favourite.data.length; favouriteStop++) {
            if (favourite.data[favouriteStop].busStop === PageModel.variables.stops[stop].id) {
              PageModel.variables.stops[stop].favouriteStop = true
            }
          }
        }
        // for (let stop in PageModel.variables.stops) {
        //   for (let favouriteStop in favourite.data) {
        //     if (favourite.data[favouriteStop].busStop === PageModel.variables.stops[stop].id) {
        //       PageModel.variables.stops[stop].favouriteStop = true
        //     }
        //   }
        // }
      }
    }
    return favourites
  })
export default moduleName
