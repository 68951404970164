/* global _, nemLogging, uiGmapGoogleMaps, socialshare, deviceDetector, ngAnimate, moment, FB */

'use strict'

// libraries

import _ from 'lodash'// eslint-disable-line
import angular from 'angular'
import nemLogging from 'angular-simple-logger'// eslint-disable-line
import uiGmapGoogleMaps from 'angular-google-maps'// eslint-disable-line
import socialshare from 'angular-socialshare'// eslint-disable-line
import deviceDetector from 'ng-device-detector'// eslint-disable-line
import ngAnimate from 'angular-animate'// eslint-disable-line
import moment from 'moment'// eslint-disable-line
import hmTouchEvents from 'angular-hammer'
import 'angular-gestures'

// configs
import router from './base/router'
import markers from './base/markers'
import init from './base/init'

// directives
import directivePopups from './base/directives/directivePopups'
import directiveInputs from './base/directives/directiveInputs'
import directiveRoutes from './base/directives/directiveRoutes'

// services
import MySocket from './base/socket/socketInit'
import MySocketService from './base/socket/socketService'
import validation from './users/validation'
import user from './users/model'
import userPage from './maps/partials/model'
import liveService from './base/services/serviceMap'
import favouriteService from './base/services/serviceFavouriteStops'
import feedbackService from './base/services/serviceFeedback'
import clickEventService from './base/services/serviceClickEvent'
import actionService from './base/services/serviceActionSocketData'

// filters
import favouriteStopsFilter from './base/filters/favouriteStops'
import busesFilter from './base/filters/buses'
import trolleybusesFilter from './base/filters/trolleybuses'

angular.module('app', [
  'ng.deviceDetector',
  'ngAnimate',
  hmTouchEvents,
  'angular-gestures',
  router,
  markers,
  init,
  directivePopups,
  directiveInputs,
  directiveRoutes,
  MySocket,
  MySocketService,
  validation,
  user,
  userPage,
  liveService,
  favouriteService,
  feedbackService,
  clickEventService,
  actionService,
  favouriteStopsFilter,
  busesFilter,
  trolleybusesFilter
])
  .run((GAuth, GApi, GData, $window, $rootScope) => {
    'ngInject'
    let adress = require('./base/constants.js.dist')
    $rootScope.gdata = GData

    GApi.load('calendar', 'v3')

    GAuth.setClient(adress.client)
    GAuth.setScope(adress.googleScope)
    GAuth.load()

    $window.fbAsyncInit = () => {
      FB.init({
        appId: adress.facebookAppId,
        version: 'v2.7'
      })
      FB.AppEvents.logPageView()
    }
    (function (d, s, id) {
      let js
      let fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) { return }
      js = d.createElement(s); js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  })
