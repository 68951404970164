/* global angular */
'use strict'

const moduleName = 'mapamagic.maps.page'

angular.module(moduleName, [])
  .controller('PageController', function ($localStorage, PageModel) {
    'ngInject'
    if (!$localStorage.userInfo) PageModel.logout()
  })

export default moduleName
