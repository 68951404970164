/* global angular */
'use strict'

const moduleName = 'mapamagic.base.markers'

angular.module(moduleName, [])
  .constant('Mark', {
    map: {
      center: {
        latitude: 49.44041,
        longitude: 32.06639
      },
      zoom: 15,
      control: {
      }
    },
    stable: {
      id: 1221,
      coords: {},
      options: {
        icon: {
          url: 'dist/images/user_location.svg',
          scaledSize: { width: 17, height: 17 }
        },
        draggable: false,
        zIndex: 100
      }
    },
    center: {
      id: 0,
      coords: {
        latitude: 49.44041,
        longitude: 32.06639
      },
      window: 'center',
      options: {
        show: true,
        icon: {
          url: 'dist/images/central_pin.png',
          scaledSize: {
            width: 50,
            height: 45
          }
        },
        zIndex: 1000
      }
    },
    windowOptions: {
      boxClass: 'infobox',
      boxStyle: {
        width: '0px',
        height: '0px',
        padding: '0px',
        fontSize: '0px',
        color: '#000000',
        backgroundColor: '#DDDDDD',
        border: '1px solid #505050',
        borderRadius: '5px'
      },
      content: '<div>awdaowdkawodkak</div>',
      disableAutoPan: true,
      maxWidth: 0,

      zIndex: 100000,
      closeBoxMargin: '5px',
      closeBoxURL: 'http://www.google.com/intl/en_us/mapfiles/close.gif',

      isHidden: false,
      pane: 'floatPane',
      enableEventPropagation: false
    },
    windowOptionsForBuble: {
      boxClass: 'infobox',
      boxStyle: {
        width: '0px',
        height: '0px',
        padding: '0px',
        fontSize: '0px',
        color: '#000000',
        backgroundColor: '#DDDDDD',
        border: '1px solid #505050',
        borderRadius: '5px'
      },
      content: '',
      disableAutoPan: true,
      maxWidth: 0,

      zIndex: null,
      closeBoxMargin: '5px',
      closeBoxURL: 'http://www.google.com/intl/en_us/mapfiles/close.gif',

      isHidden: false,
      pane: 'floatPane',
      enableEventPropagation: false
    }
  })

export default moduleName
