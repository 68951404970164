/* global angular */
'use strict'

const moduleName = 'mapamagic.maps.main'

angular.module(moduleName, [])
  .controller('MainController', function ($window, User, PageModel, deviceDetector) {
    'ngInject'
    let vm = this
    vm.browser = deviceDetector.browser
    vm.variables = PageModel.variables
    vm.gifValue = {
      gifSrc: 'animation.gif',
      gifStatic: 'pause.svg'
    }
    vm.openGif = (data, trolleybusGo) => { // to stop the gif
      if (trolleybusGo) {
        vm.gifStatic = 'trolley_go.gif'
      } else if (data.gifSrc === 'animation.gif') {
        data.gifSrc = 'animation_static.png'
        data.gifStatic = 'play.svg'
      } else {
        data.gifSrc = 'animation.gif'
        data.gifStatic = 'pause.svg'
      }
      return data
    }
  })

export default moduleName
