/* global angular */
'use strict'

const inputTemplate = './app/partials/templates/input.html'

const moduleName = 'mapamagic.directives.directiveInputs'

angular.module(moduleName, [])
  .directive('mapaInput', $window => {
    'ngInject'
    return {
      restrict: 'E',
      scope: {
        modelValue: '=',
        modelClick: '=',
        statusSubmitted: '=',
        showMessage: '=',
        validator: '&',
        controllerType: '@',
        inputTypeForClassMessage: '@',
        name: '@',
        placeholder: '@',
        text: '@'
      },
      templateUrl: inputTemplate
    }
  })

export default moduleName
