/* global angular */
'use strict'

const moduleName = 'mapamagic.maps.map'

angular.module(moduleName, ['uiGmapgoogle-maps', 'hmTouchEvents'])
  .controller('MapController', function ($window, $localStorage, $rootScope, $q, $timeout, uiGmapIsReady, Mark, socketService, PageModel, liveService, favouriteService, clickEventService) {
    'ngInject'
    let timerId
    // $timeout.cancel(timerId)
    let adress = require('../../base/constants')

    if (!$localStorage.userInfo) {
      return false
    }

    const updatedCoords = PageModel.updateCoords()

    let vm = this
    vm.variables = PageModel.variables // object of variables that used at map page
    vm.variables.windowOptionsForBuble = Mark.windowOptionsForBuble
    vm.dataGot = {} // all data: stops, samples and routes
    vm.map = Mark.map // options for google map default location
    vm.stroke = { // polylins options
      color: '#5fa1d1',
      weight: 3
    }

    vm.map.center = {
      latitude: updatedCoords.lat,
      longitude: updatedCoords.lng
    }

    // if (!google) {
    //   vm.map.center = {
    //     latitude: 49.4404101,
    //     longitude: 32.06639
    //   }
    // }

    vm.toggleLiveView = liveService.toggleLiveView // functions that called when click on buttons of live view
    $localStorage.dataFromWindow = 'SHOW_WINDOW'

    vm.dragUp = event => {
      event.preventDefault()
      if ($window.innerWidth <= 1120 && PageModel.variables.checked !== 'tall' && PageModel.isMobile.any()) {
        vm.toggleLiveView('tall')
      }
    }

    // events of the map
    vm.map.events = {
      click () {},
      drag: newCoords,
      resize: newCoords,
      dragend () {
        if (!vm.showMessage) {
          vm.createClosestStops()
          vm.showMessage = PageModel.permissionToDrawBuble = false
          if (clickEventService.currentElement.model) {
            clickEventService.currentElement.model.showBuble = false
          }
        }
      },
      zoom_changed () {
        newCoords()
        vm.showMessage = PageModel.permissionToDrawBuble = false
        vm.showMessage = PageModel.permissionToDrawBuble = false
        if (clickEventService.currentElement.model) {
          clickEventService.currentElement.model.showBuble = false
        }
        clickEventService.propertyForControlRoutesIteration = []
        clickEventService.busPermission = clickEventService.trolleybusPermission = false
        clickEventService.currentElement.model.options.icon.url = 'dist/images/otherPin.png'
        clickEventService.currentElement = []
        vm.variables.activeModelForBuble.showBuble = false
        $timeout.cancel(timerId)
        PageModel.timeouts.mapEventZoomChangedTimeout = $timeout(vm.createClosestStops, 0)
      }
    }

    // get static data from the server and wait until the map is ready
    let promises = {
      map: uiGmapIsReady,
      bus: PageModel.getData('bus'), // array of bus
      routes: PageModel.getData('routes'), // array of routes
      sample: PageModel.getData('sample'), // array of sample
      stops: PageModel.getData('stops') // array of stops
    }

    // The creation of new markers

    $q.all(promises)
      .then((values) => {
        vm.dataGot = values
      })
      .then(() => {
        PageModel.createAllStops(vm.dataGot)
      })
      .then(() => {
        PageModel.sortStops(vm.variables.center)
        favouriteService.createStopsIdForFavouriteTabOnTheMap()
        createPostTime(PageModel.variables.stopsIdArr.join(','))
        PageModel.createlistOfRoutesForRunOnBable()
      })
      .catch(error => console.error('got an error in processing', error))
      .finally(() => {
        let qAllPromisesInFinallyTimeout = $timeout(() => {
          // if (PageModel.isMobile.iOS()) {
          //     angular.element(document.querySelectorAll(".tabs")).addClass('ios');
          // }
          favouriteService.checkIfAppUserHaveThatFavouriteStop()
          vm.variables.showSpinner = false
          let qAllPromisesInFinallyInsideTimeout = $timeout(() => {
            vm.variables.smoothShowSpinner = false
            PageModel.clearForDynamicTimeouts(qAllPromisesInFinallyTimeout)
            PageModel.clearForDynamicTimeouts(qAllPromisesInFinallyInsideTimeout)
          }, 500)
        }, 2500)
      })

    // a function that creates an array of nearest stops and start XMLHttpRequest
    vm.createClosestStops = () => {
      PageModel.clearForDynamicTimeouts(PageModel.timeouts.mapEventZoomChangedTimeout)
      PageModel.clearForDynamicTimeouts(PageModel.timeouts.viewChangeEventZoomChangedTimeout)
      PageModel.clearForDynamicTimeouts(PageModel.timeouts.tabChangeEventZoomChangedTimeout)
      PageModel.clearForDynamicTimeouts(PageModel.timeouts.sidebarToggleEventInsideTimeout)
      if (PageModel.isMobile.any()) {
        let createClosestStopsTimeout = $timeout(() => {
          PageModel.sortStops(vm.variables.center)
          favouriteService.createStopsIdForFavouriteTabOnTheMap() // variables.stopsIdArr - array of very closest stops id for socket emmit + favourite stops id
          createPostTime(PageModel.variables.stopsIdArr.join(','))
          PageModel.clearForDynamicTimeouts(createClosestStopsTimeout)
        }, 500)
      } else {
        PageModel.sortStops(vm.variables.center)
        favouriteService.createStopsIdForFavouriteTabOnTheMap() // variables.stopsIdArr - array of very closest stops id for socket emmit + favourite stops id
        createPostTime(PageModel.variables.stopsIdArr.join(','))
      }
      if (vm.variables.tab === 'Second') {
        for (let i = 0; i < 3; i++) {
          vm.variables.stops[i].options.icon.url = 'dist/images/otherPin.png'
        }
      }
    }

    // a function that changes coordinates of the central marker
    function newCoords () {
      let newcoords = {
        latitude: vm.map.control.getGMap().getCenter().lat(),
        longitude: vm.map.control.getGMap().getCenter().lng()
      }
      let newCoordsTimeout = $timeout(() => {
        vm.variables.center.coords = newcoords
        PageModel.clearForDynamicTimeouts(newCoordsTimeout)
      }, 0)
    }

    // function to get data of time by XMLHttpRequest
    function createPostTime (stops) {
      PageModel.getDataViaXmlhttprequest('stops/' + stops + '/time')
        .then(value => {
          if (value.data.length !== 0) {
            PageModel.writeDataFromXmlhttp(value, vm.dataGot)
            socketService.subscribe(PageModel.variables.stopsIdArr)
          } else {
            console.info('There is no data from server!')
          }
        })
        .catch(error => console.log('got an error in processing', error))
      socketService.subscribeData = vm.dataGot
    }

    // to find location of user
    vm.stable = Mark.stable
    // vm.https = window.location.protocol === 'https:'
    vm.showMe = () => {
      if (navigator.geolocation) {
        let options = {
          enableHighAccuracy: true,
          maximumAge: 1000,
          timeout: 20000
        }
        navigator.geolocation.getCurrentPosition(position => {
          vm.stable.coords.latitude = PageModel.variables.center.coords.latitude = position.coords.latitude
          vm.stable.coords.longitude = PageModel.variables.center.coords.longitude = position.coords.longitude
          PageModel.sortStops()
        }, error => console.error(`ERROR (${error.code}): ${error.message}`, error), options)
      }
    }

    // icon of all stops on the route
    vm.allStopsIcon = {
      scaledSize: {
        height: 35,
        width: 23
      },
      opacity: 0.65,
      url: './dist/images/otherPin.png'
    }

    // different events for different markers type
    $rootScope.$on('viewChangeEvent', () => {
      if (vm.variables.showClosestStops && vm.variables.tab === 'First') {
        vm.createClosestStops()
        vm.map.events = {
          click () {
          },
          drag: newCoords,
          resize: newCoords,
          dragend () {
            if (!vm.showMessage) {
              vm.createClosestStops()
              vm.showMessage = PageModel.permissionToDrawBuble = false
              if (clickEventService.currentElement.model) {
                clickEventService.currentElement.model.showBuble = false
              }
            }
          },
          zoom_changed () {
            newCoords()
            PageModel.timeouts.viewChangeEventZoomChangedTimeout = $timeout(vm.createClosestStops, 0)
          }
        }
      } else if (vm.variables.showClosestStops && vm.variables.tab === 'Second') {
        for (let i = 0; i < 3; i++) {
          vm.variables.stops[i].options.icon.url = 'dist/images/otherPin.png'
        }
        vm.map.events = {
          click () {
          },
          drag: newCoords,
          resize: newCoords,
          dragend () {
            newCoords()
          },
          zoom_changed: newCoords
        }
      } else {
        vm.variables.checked = 'buses'
        vm.map.events = {
          click () {
            let viewChangeEventMapEventClickTimeout = $timeout(() => {
              vm.variables.activeModel.show = false
              vm.variables.busClicked = false
              PageModel.clearForDynamicTimeouts(viewChangeEventMapEventClickTimeout)
            }, 0)
          },
          drag: newCoords,
          resize: newCoords,
          dragend () {
            newCoords()
            // vm.showMessage = PageModel.permissionToDrawBuble = false
            // if (clickEventService.currentElement.model) clickEventService.currentElement.model.showBuble = false
          },
          zoom_changed: newCoords
        }
      }
    })
    $rootScope.$on('tabChangeEvent', () => {
      $timeout.cancel(timerId)
      vm.variables.activeModelForBuble.showBuble = true
      clickEventService.propertyForControlRoutesIteration = []
      clickEventService.busPermission = clickEventService.trolleybusPermission = false
      clickEventService.currentElement = []
      vm.showMessage = false
      vm.toggleForElement = {}
      vm.variables.activeModelForBuble = {}
      vm.variables.activeModel = {}
      if (vm.variables.tab === 'First') {
        vm.createClosestStops()
        vm.map.events = {
          click () {
          },
          drag: newCoords,
          resize: newCoords,
          dragend () {
            if (!vm.showMessage) {
              vm.createClosestStops()
              vm.showMessage = PageModel.permissionToDrawBuble = false
              if (clickEventService.currentElement.model) {
                clickEventService.currentElement.model.showBuble = false
              }
            }
          },
          zoom_changed () {
            newCoords()
            PageModel.timeouts.tabChangeEventZoomChangedTimeout = $timeout(vm.createClosestStops, 0)
          }
        }
      } else {
        for (let i = 0; i < 3; i++) {
          vm.variables.stops[i].options.icon.url = 'dist/images/otherPin.png'
        }
        vm.map.events = {
          click () {
          },
          drag: newCoords,
          resize: newCoords,
          dragend () {
            newCoords()
            // vm.showMessage = PageModel.permissionToDrawBuble = false
            // if (clickEventService.currentElement.model) clickEventService.currentElement.model.showBuble = false
          },
          zoom_changed: newCoords
        }
      }
    })
    $rootScope.$on('sidebarToggleEvent', () => {
      PageModel.resizeCounter++
      let sidebarToggleEventTimeout = $timeout(() => {
        let newEvent = $window.document.createEvent('Event')
        newEvent.initEvent('resize', true, true)
        $window.dispatchEvent(newEvent)
      }, 500)
        .then(() => {
          newCoords()
          PageModel.clearForDynamicTimeouts(sidebarToggleEventTimeout)
          PageModel.timeouts.sidebarToggleEventInsideTimeout = $timeout(vm.createClosestStops, 0)
        })
    })

    let canResize = true // flag for resize watcher
    angular.element($window).bind('resize', () => {
      let element = $window.document.getElementsByClassName('gmnoprint')[5]
      element.className = 'gmnoprint left'
      // make transition between desktop and mobile smooth
      // vm.variables.showSidebar = true
      if (canResize) {
        let resizeTimeout = $timeout(() => {
          canResize = false
          let newEvent = $window.document.createEvent('Event')
          newEvent.initEvent('resize', true, true)
          $window.dispatchEvent(newEvent)
        }, 200)
          .then(() => {
            PageModel.clearForDynamicTimeouts(resizeTimeout)
            canResize = true
            let backButton = $window.document.getElementsByClassName('wrapper-back-button')[0]
            let sideBar = $window.document.getElementById('sidebar')
            if (backButton && backButton.className === 'wrapper-back-button ng-scope' && PageModel.resizeCounter === 1) {
              if (sideBar.className === 'sidebar_wrapper ng-scope') {
                backButton.className = 'wrapper-back-button ng-scope left'
              }
            } else if (backButton && backButton.className === 'wrapper-back-button ng-scope left' && PageModel.resizeCounter === 1) {
              if (sideBar.className === 'sidebar_wrapper ng-scope active') {
                backButton.className = 'wrapper-back-button ng-scope'
              }
            }
          })
      }
      if ($window.innerWidth <= 700) {
        vm.busesText = 'Рух'
        vm.listText = 'Список'
      } else {
        vm.busesText = 'Рух транспорту'
        vm.listText = 'Список зупинок'
      }
    })

    // events of the marker
    vm.toggleForElement = {}
    vm.map.markersEvents = {
      click (marker, eventName, model) {
        $timeout.cancel(timerId)
        PageModel.variables.showMapSpinner = true
        PageModel.permissionToDrawBuble = true
        PageModel.markerClick(marker)
        vm.createClosestStops()
        vm.toggleForElement[model.idKey] = !vm.toggleForElement[model.idKey]
        if (vm.toggleForElement.length !== 0) {
          for (let value in vm.toggleForElement) {
            if (value !== model.idKey + '') {
              vm.toggleForElement[value] = false
            }
          }
        }
        clickEventService.propertyForControlRoutesIteration = []
        clickEventService.busPermission = clickEventService.trolleybusPermission = false
        clickEventService.currentElement.model = model
        clickEventService.currentElement.marker = marker
        liveService.windowManipulationForRouteClickEventOnTheMainMap(true, model.idKey)
        if (vm.toggleForElement[model.idKey] && model.options.icon.url === 'dist/images/otherPin.png') {
          model.options.icon.url = 'dist/images/otherPin-full.png'
        }
        if (!vm.toggleForElement[model.idKey]) {
          PageModel.variables.showMapSpinner = false
          vm.showMessage = PageModel.permissionToDrawBuble = false
          if (clickEventService.currentElement.model) {
            clickEventService.currentElement.model.showBuble = false
          }
        } else {
          vm.variables.activeModelForBuble.showBuble = true
          checkMarkerDataForBuble(marker, model, model.idKey)
        }
      }
    }

    function checkMarkerDataForBuble (marker, model, id) {
      // let permission = true
      // if (PageModel.listOfRoutesForRunOnBable.find(routeList => routeList.id === id)) {
      //   permission = false
      // }
      // PageModel.listOfRoutesForRunOnBable.forEach(routeList => {
      //   if (routeList.id === id) permission = false
      // })
      if (!PageModel.listOfRoutesForRunOnBable.find(routeList => routeList.id === id)) {
        PageModel.timeouts.checkMarkerDataForBubleTimeout = $timeout(() => checkMarkerDataForBuble(marker, model, id), 500)
      } else {
        checkMarkerOnClick(id)
        checkMarkerOnClickOnCloseButton(id)
        PageModel.variables.showMapSpinner = false
        let checkMarkerDataForBubleInsideTimeout = $timeout(() => clickEventService.getPermissionFromData(id), 0)
          .then(() => {
            return new Promise((resolve, reject) => {
              clickEventService.clickEventMap(marker, model, id)
              resolve()
            })
          })
          .then(() => {
            PageModel.clearForDynamicTimeouts(checkMarkerDataForBubleInsideTimeout)
            PageModel.clearForDynamicTimeouts(PageModel.timeouts.checkMarkerDataForBubleTimeout)
            PageModel.clearForDynamicTimeouts(PageModel.timeouts.checkMarkerOnClickOnCloseButtonFirstTimeout)
            PageModel.clearForDynamicTimeouts(PageModel.timeouts.checkMarkerOnClickOnCloseButtonSecondTimeout)
          })
      }
    }

    function checkMarkerOnClickOnCloseButton (id) {
      let elements = $window.document.getElementsByClassName('buble-wrapper-close-button')
      if (elements[0]) {
        if (elements[0].classList[1] === `${id}-pressed`) {
          vm.createClosestStops()
          vm.toggleForElement[id] = false
          vm.showMessage = PageModel.permissionToDrawBuble = false
          if (clickEventService.currentElement.model) {
            clickEventService.currentElement.model.showBuble = false
          }
          clickEventService.propertyForControlRoutesIteration = []
          clickEventService.busPermission = clickEventService.trolleybusPermission = false
          clickEventService.currentElement.model.options.icon.url = 'dist/images/otherPin.png'
          clickEventService.currentElement = []
          vm.variables.activeModelForBuble.showBuble = false
          $timeout.cancel(timerId)
        }
        if (vm.showMessage) {
          timerId = $timeout(() => checkMarkerOnClickOnCloseButton(id), 200)
        }
      } else {
        timerId = $timeout(() => checkMarkerOnClickOnCloseButton(id), 200)
      }
    }

    function checkMarkerOnClick (id) {
      PageModel.variables.windowOptionsForBuble.content = ''
      PageModel.variables.windowOptionsForBuble.boxStyle.display = 'none'
      PageModel.variables.windowOptionsForBuble.boxStyle.width = PageModel.variables.windowOptionsForBuble.boxStyle.height = '0px'
      if (!vm.markerOnClick || vm.markerOnClick === id) {
        vm.showMessage = !vm.showMessage
      } else if (vm.markerOnClick !== id && !vm.showMessage) {
        vm.showMessage = true
      }
      vm.markerOnClick = id
    }

    vm.map.markersFavouritesEvents = {
      click (marker, eventName, model) {
        $timeout.cancel(timerId)
        vm.createClosestStops()
        PageModel.variables.showMapSpinner = true
        PageModel.permissionToDrawBuble = true
        PageModel.markerClick(marker)
        vm.toggleForElement[model.idKey] = !vm.toggleForElement[model.idKey]
        if (vm.toggleForElement.length !== 0) {
          for (let value in vm.toggleForElement) {
            if (value !== model.idKey + '') {
              vm.toggleForElement[value] = false
            }
          }
        }
        clickEventService.propertyForControlRoutesIteration = []
        clickEventService.busPermission = clickEventService.trolleybusPermission = false
        clickEventService.currentElement.model = model
        clickEventService.currentElement.marker = marker
        liveService.windowManipulationForRouteClickEventOnTheMainMap(true, model.idKey)
        if (model.options.icon.url === 'dist/images/otherPin.png') {
          model.options.icon.url = 'dist/images/otherPin-full.png'
        } else {
          model.options.icon.url = 'dist/images/otherPin.png'
        }
        if (!vm.toggleForElement[model.idKey]) {
          PageModel.variables.showMapSpinner = false
          vm.showMessage = PageModel.permissionToDrawBuble = false
          if (clickEventService.currentElement.model) clickEventService.currentElement.model.showBuble = false
        } else {
          vm.variables.activeModelForBuble.showBuble = true
          checkMarkerDataForBuble(marker, model, model.idKey)
        }
      }
    }
    $rootScope.$on('functionToOpenRouteAtSidebar', () => {
      vm.showMessage = false
    })
    $rootScope.$on('functionToRewriteBuble', () => {
      if (clickEventService.currentElement.model && (clickEventService.currentElement.model.show || clickEventService.currentElement.model.showBuble)) {
        clickEventService.propertyForControlRoutesIteration = []
        clickEventService.busPermission = false
        clickEventService.trolleybusPermission = false
        clickEventService.getPermissionFromData(clickEventService.currentElement.model.idKey)
        if (clickEventService.currentElement.marker && clickEventService.currentElement.model && PageModel.permissionToDrawBuble) {
          clickEventService.clickEventMap(clickEventService.currentElement.marker, clickEventService.currentElement.model, clickEventService.currentElement.model.idKey)
        }
      }
    })
    PageModel.replaceGMButtons()
    vm.backToStartMap = () => $rootScope.$broadcast('functionToCloseSidebarListOfStops')
  })

export default moduleName
