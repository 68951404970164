/* global angular, FB */
'use strict'

const moduleName = 'mapamagic.user.selectCity'

angular.module(moduleName, [])
  .controller('SelectCityController', function ($window, $localStorage, $timeout, $state, newSocket, socketService, User, Validation, PageModel) {
    'ngInject'
    let vm = this
    vm.os = User.os
    if (PageModel.variables.stops.length !== 0) {
      PageModel.logout()
    }
    vm.closeDownload = () => {
      User.os = vm.os = 'other'
    }

    if (!$localStorage.userInfo) {
      $state.go('mapa.registration')
    }

    vm.selectedCity = null
    vm.cities = [
      {
        title: 'Черкаси',
        id: 0
      },
      {
        title: 'Дрогобич',
        id: 4
      }
    ]
    vm.isOpened = false
    vm.toggleDropDown = () => {
      vm.isOpened = !vm.isOpened
    }
    vm.select = id => {
      vm.selectedCity = vm.cities.find(city => city.id === id)
      vm.isOpened = false
    }
    vm.selectCitySubmit = () => {
      // If selected Drogobuch -> make request
      if (vm.selectedCity.id === 4) {
        const data = {
          user: {
            city: vm.selectedCity.id
          }
        }
        User.changeCity(data)
          .then(res => {
            if (res) {
              $timeout(() => {
                PageModel.variables.showSpinner = PageModel.variables.smoothShowSpinner = true
                $localStorage.newData = {}
                $localStorage.userInfo = res
                socketService.auth($localStorage.userInfo.data.apikey, () => { $timeout(() => $state.go('mapa.page'), 500) })
              }, 0)
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        $timeout(() => {
          PageModel.variables.showSpinner = PageModel.variables.smoothShowSpinner = true
          socketService.auth($localStorage.userInfo.data.apikey, () => { $timeout(() => $state.go('mapa.page'), 500) })
        }, 0)
      }

    }
    vm.closeErrorMassageAfterAnswerFromServer = value => {
      vm[value] = !vm[value]
    }
    vm.reload = () => $window.location.reload()
  })

export default moduleName
