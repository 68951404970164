/* global angular */
'use strict'

const routeTemplate = './app/partials/templates/routesBuses.html'

const moduleName = 'mapamagic.directives.directiveRoutes'

angular.module(moduleName, [])
  .directive('routes', () => {
    'ngInject'
    return {
      restrict: 'E',
      templateUrl: routeTemplate,
      scope: '= this',
      link (scope, element, attr) {
        scope.sid = attr.directiveSid
        scope.elementIndex = attr.directiveIndex
        scope.type = attr.directiveType
      }
    }
  })

export default moduleName
