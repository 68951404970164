/* global angular */
'use strict'

const moduleName = 'mapamagic.base.filter.trolleybuses'

angular.module(moduleName, [])
  .filter('trolleybusesFilter', function () {
    'ngInject'
    return function (item) {
      let array = []
      if (item) {
        item.forEach((element, index) => {
          if (element.type === 1) {
            array.push(element)
          }
        })
      }
      return array
    }
  })

export default moduleName
