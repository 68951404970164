/* global angular */
'use strict'

const moduleName = 'mapamagic.maps.header'

angular.module(moduleName, [])
  .controller('HeaderController', function ($localStorage, $timeout, deviceDetector, PageModel, User, Validation, feedbackService) {
    'ngInject'

    if (!$localStorage.userInfo) {
      return false
    }
    let vm = this
    vm.logout = () => {
      PageModel.logout()
    } // logout function
    vm.isCherkassy = $localStorage.userInfo.data.city === 1
    vm.inputFields = [
      {
        name: 'feedback',
        value: null,
        controllerType: 'header',
        inputTypeForClassMessage: 'pop-up',
        placeholder: 'Відгук',
        validator: Validation.validateOther,
        text: 'Це поле є обов’язковим.',
        modelValue: null,
        showMessage: false,
        submitted: false
      }
    ]

    vm.textForShare = 'Я корисуюсь веб додатком MapaMagic для слідкування за рухом транспорту у м. Черкаси в реальному часі. Спробуйте й Ви! http://mapamagic-webapp.stageserver.org/'

    vm.toggleMobileMenu = false
    vm.isSelectCityDropDown = false
    vm.variables = PageModel.variables
    vm.popUps = {
      feedback: false, // Pop up state for feedback
      share: false, // Pop up for share
      rating: false, // Pop up for share
      download: false
    }
    vm.themeOfFeedback = null
    vm.dataDevice = deviceDetector.os// for rating detect os system...
    vm.showCopied = 'false' // for show copy o not at the popup social share

    vm.toggleSelectCityDropDown = () => {
      this.isSelectCityDropDown = !this.isSelectCityDropDown
    }

    vm.selectCitySubmit = cityId => {
      const currentCityId = $localStorage.userInfo.data.city
      vm.toggleSelectCityDropDown()
      if (currentCityId === cityId) return
      const data = {
        user: {
          city: cityId
        }
      }
      User.changeCity(data)
        .then(res => {
          if (res) {
            $timeout(() => {
              $localStorage.newData = {}
              $localStorage.userInfo = res
              window.location.reload()
            }, 0)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    vm.closeErrorMessage = (data, status, event, validationResult) => {
      event.stopPropagation()
      if (!validationResult) {
        if (status || data === 'closeAll') {
          for (let field = 0; field < vm.inputFields.length; field++) {
            vm.inputFields[field].showMessage = vm.inputFields[field].name === data
          }
          // vm.inputFields.forEach(field => {
          //   field.showMessage = field.name === data
          // })
        }
      } else {
        for (let field = 0; field < vm.inputFields.length; field++) {
          vm.inputFields[field].showMessage = false
        }
        // vm.inputFields.forEach(field => {
        //   field.showMessage = false
        // })
      }
    }
    // function to open/close pop up
    vm.popUpOpen = name => {
      for (let field = 0; field < vm.inputFields.length; field++) {
        vm.inputFields[field].submitted = vm.inputFields[field].showMessage = true
        vm.inputFields[field].value = vm.inputFields[field].modelValue = vm.themeOfFeedback = null
      }
      // vm.inputFields.forEach(field => {
      //   field.submitted = field.showMessage = false
      //   field.value = field.modelValue = vm.themeOfFeedback = null
      // })
      for (let popup in vm.popUps) {
        if (popup === name) {
          vm.popUps[popup] = !vm.popUps[popup]
        } else {
          vm.popUps[popup] = false
        }
      }
    }
    vm.popUpFeedback = name => {
      let data = {}
      for (let field = 0; field < vm.inputFields.length; field++) {
        vm.inputFields[field].submitted = true
        data[vm.inputFields[field].name] = vm.inputFields[field].value
      }
      // vm.inputFields.forEach(field => {
      //   field.submitted = true
      //   data[field.name] = field.value
      // })
      data.theme = vm.themeOfFeedback
      if (User.checkInputsValidation(vm.inputFields)) {
        feedbackService.sendFeedbackServiceToTheServer(data.theme, data.feedback)
        vm.popUpOpen(name)
        vm.inputFields.forEach(field => {
          field.submitted = field.showMessage = false
          field.value = field.modelValue = vm.themeOfFeedback = null
        })
      }
    }
    vm.showCopy = (event, message) => {
      vm.showCopied = message ? 'show-error' : 'show'
      let showCopyTimeout = $timeout(() => {
        vm.showCopied = message ? 'hide-error' : 'hide'
      }, 2000).then(() => {
        let showCopyInsideTimeout = $timeout(() => {
          vm.showCopied = 'false'
        }, 1000).then(() => {
          PageModel.clearForDynamicTimeouts(showCopyTimeout)
          PageModel.clearForDynamicTimeouts(showCopyInsideTimeout)
        })
      })
    }
  })

export default moduleName
